import NotValidSirenError from '~/services/sirenError'
import {
  normalizeArrayFieldsInObject,
  normalizeBeneficiaires,
  normalizeBodacc,
  normalizeBodaccPCL,
  normalizeBrands,
  normalizeEstablishments,
  normalizeFinanceReports,
  normalizeIsinFinanceReports,
  normalizeJuristicDocuments,
  normalizeObservations,
  normalizeRepresentants,
  normalizeSimilarCompanies
} from '~/services/normalizeCompanyResponse'
import Api from './Api'
import ResponseError from '~/services/responseError'

class LegalUnites extends Api {
  constructor (_axios) {
    super(_axios, '/legal-unites')
  }

  search (params) {
    return this.post('/search', params)
  }

  activities (params) {
    return this.get('/activites', params)
  }

  formsLegal (params) {
    return this.get('/forms-legal', params)
  }

  info (siren) {
    return this.get(`/${siren}/info`)
  }

  show (siren) {
    const regex = /^\d{9}$/
    if (!regex.test(siren)) {
      throw new NotValidSirenError('404')
    }

    return this.get(`/${siren}`).then(res => {
      if (res instanceof ResponseError) {
        throw res
      }

      if (res === false) {
        return res
      }

      const fieldsToNormalize = [
        'establishments',
        'bodacc',
        'brands',
        'finance_reports',
        'isin_finance_reports',
        'beneficiaires',
        'representants',
        'observations',
        'juristic_documents',
        'similar_companies'
      ]
      normalizeArrayFieldsInObject(res, fieldsToNormalize)

      function isEachObjectFieldIsNull (item) {
        return !Object.values(item).filter(value => value !== null).length
      }
      function hasAnyDataInItem (arr) {
        return !!arr.map(isEachObjectFieldIsNull).filter(item => !item).length
      }
      if (!res.bodacc_pcl || Array.isArray(res.bodacc_pcl) || typeof res.bodacc_pcl !== 'object') {
        res.bodacc_pcl = {
          group: [],
          non_group: []
        }
      } else {
        if (res.bodacc_pcl.group?.length) {
          res.bodacc_pcl.group = res.bodacc_pcl.group
            .filter(item => item)
          res.bodacc_pcl.group.forEach(childItem => {
            // eslint-disable-next-line no-param-reassign
            childItem.items = childItem.items.filter(item => item)
          })
        }
        if (!res.bodacc_pcl.group || !hasAnyDataInItem(res.bodacc_pcl.group)) {
          res.bodacc_pcl.group = []
        }
        if (res.bodacc_pcl.non_group?.length) {
          res.bodacc_pcl.non_group = res.bodacc_pcl.non_group.filter(item => item)
        }
        if (!res.bodacc_pcl.non_group || !hasAnyDataInItem(res.bodacc_pcl.non_group)) {
          res.bodacc_pcl.non_group = []
        }
      }

      res.similar_companies = res.similar_companies.filter(item => item.alias && item.company_name).map(i => {
        i.alias = `/entreprise/${i.alias}`
        return i
      })

      normalizeEstablishments(res.establishments)
      normalizeBodacc(res.bodacc)
      normalizeBodaccPCL(res.bodacc_pcl)
      normalizeBrands(res.brands)
      normalizeFinanceReports(res.finance_reports)
      normalizeIsinFinanceReports(res.isin_finance_reports)
      normalizeBeneficiaires(res.beneficiaires)
      normalizeRepresentants(res.representants)
      normalizeObservations(res.observations)
      normalizeJuristicDocuments(res.juristic_documents)
      normalizeSimilarCompanies(res.similar_companies)

      fieldsToNormalize.forEach(field => {
        const hasAnyDataInItems = () => !!res[field].map(isEachObjectFieldIsNull).filter(item => !item).length
        // eslint-disable-next-line no-param-reassign
        if (!res[field] || !hasAnyDataInItems()) res[field] = []
      })

      return res
    })
  }

  all (params) {
    return this.get('', params)
  }

  paginateBrands (siren, params) {
    return this.getWithPaginate(`/${siren}/brands`, params).then(res => {
      normalizeBrands(res)
      return res
    })
  }

  paginateBodacc (siren, params) {
    return this.getWithPaginate(`/${siren}/bodacc`, params)
      .then(res => {
        normalizeBodacc(res)
        return res
      })
  }

  paginateEstablishments (siren, params) {
    return this.getWithPaginate(`/${siren}/establishments`, params).then(res => {
      normalizeEstablishments(res)
      return res
    })
  }

  paginateBeneficiaires (siren, params) {
    return this.getWithPaginate(`/${siren}/beneficiaires`, params).then(res => {
      normalizeBeneficiaires(res)
      return res
    })
  }

  paginateIsinFinanceReport (siren, params) {
    return this.getWithPaginate(`/${siren}/isin-finance-report`, params).then(res => {
      normalizeIsinFinanceReports(res)
      return res
    })
  }

  paginateJuristicDocuments (siren, params) {
    return this.getWithPaginate(`/${siren}/juristic-documents`, params)
      .then(res => {
        normalizeJuristicDocuments(res)
        return res
      })
  }

  graph (siren, params, parentNameArr) {
    return this.getWithArrayOfObjs(`/${siren}/graph`, params, parentNameArr)
  }

  paginateDirigeants (siren, params) {
    return this.getWithPaginate(`/${siren}/representants`, params)
      .then(res => {
        normalizeRepresentants(res)
        return res
      })
  }

  paginateObservations (siren, params) {
    return this.getWithPaginate(`/${siren}/observations`, params)
      .then(res => {
        normalizeObservations(res)
        return res
      })
  }
}

export default LegalUnites
