<template>
  <div class="layout">
    <LazyHydrate v-if="isMobile" when-idle>
      <MainHeaderMobile />
    </LazyHydrate>
    <LazyHydrate v-else when-idle>
      <MainHeaderDesktop />
    </LazyHydrate>
    <main>
      <Nuxt />
    </main>
    <LazyHydrate when-visible>
      <MainFooter />
    </LazyHydrate>
    <client-only>
      <LazyHydrate when-idle>
        <ModalRoot />
      </LazyHydrate>
    </client-only>
    <PopUpPremium :blocked="blocked" />
    <LazyHydrate when-idle>
      <HoveringNotificationsTrack />
    </LazyHydrate>
    <client-only>
      <LazyHydrate when-idle>
        <Up />
      </LazyHydrate>
    </client-only>
    <client-only>
      <LazyHydrate when-idle>
        <LoginModal />
      </LazyHydrate>
      <LazyHydrate when-idle>
        <RegistrationModal />
      </LazyHydrate>
      <LazyHydrate when-idle>
        <PasswordRecoveryModal />
      </LazyHydrate>
      <LazyHydrate when-idle>
        <RejectFormModal />
      </LazyHydrate>
    </client-only>
    <portal-target name="root" />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import deviceInfo from '@/mixins/deviceInfo'
import HoveringNotificationsTrack from '~/components/layout/notifications/HoveringNotificationsTrack.vue'
import MainHeaderDesktop from '@/components/layout/MainHeaderDesktop.vue'
import MainHeaderMobile from '@/components/layout/MainHeaderMobile.vue'
import analytics from '~/mixins/analytics'
import baseHeader from '~/mixins/baseHeader'

export default {
  components: {
    LazyHydrate,
    Up: () => import('~/components/layout/Up'),
    MainHeaderDesktop,
    MainHeaderMobile,
    MainFooter: () => import('@/components/layout/MainFooter.vue'),
    PopUpPremium: () => import('~/components/layout/PopUpPremium.vue'),
    HoveringNotificationsTrack,
    ModalRoot: () => import('~/components/modals/ModalRoot'),
    LoginModal: () => import('~/components/modals/LoginModal'),
    RegistrationModal: () => import('~/components/modals/RegistrationModal'),
    PasswordRecoveryModal: () => import('~/components/modals/PasswordRecoveryModal'),
    RejectFormModal: () => import('@/components/modals/RejectFormModal')
  },
  mixins: [deviceInfo, analytics, baseHeader],
  data () {
    return {
      blocked: false
    }
  },
  created () {
    this.$root.$on('disable-premium-popup', this.blockPopup)
    this.$root.$on('activate-premium-popup', this.unlockPopup)
  },
  mounted () {
    this.$root.$on('auth', this.goAuth)
  },
  beforeDestroy () {
    this.$root.$off('auth', this.goAuth)
    this.$root.$off('disable-premium-popup', this.blockPopup)
    this.$root.$off('activate-premium-popup', this.unlockPopup)
  },
  methods: {
    goAuth () {
      if (!this.$modals.openedModals.includes(this.$modals.names.LoginModal)) {
        this.$modals.showModal(this.$modals.names.LoginModal)
      }
    },
    blockPopup () {
      this.blocked = true
    },
    unlockPopup () {
      this.blocked = false
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}

main {
  z-index: 1;
}
</style>
