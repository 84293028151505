// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-holder{position:fixed;left:0;top:0;right:0;bottom:0;z-index:-1}.modal-holder.active{z-index:10}.__modal-root{position:fixed;left:0;top:0;right:0;bottom:0;opacity:1;display:flex;background:rgba(0,0,0,.6)}.fade-enter-active{transition:opacity var(--bg-show-time)}.fade-leave-active{transition:opacity var(--bg-hide-time)}.fade-enter,.fade-leave-to{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet-landscape": "1160px",
	"tablet-portrait": "900px",
	"phone": "640px"
};
module.exports = ___CSS_LOADER_EXPORT___;
