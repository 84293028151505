export default {
  head () {
    return {
      title: this.$store.getters['scripts/getMerchantName'] || 'Docubiz',
      htmlAttrs: {
        lang: 'fr'
      },
      script: [
        {
          src: '/scripts/gtag.js'
        }
      ],
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${this.$store.getters['scripts/getMerchantName'] || 'Docubiz'} : kbis, Tva intra, Siren/Siret, bilans - L\'info légale en illimité !`
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: this.$store.getters['scripts/getMerchantName'] || 'Docubiz'
        }
      ]
    }
  }
}
