import Api from './Api'

class Courts extends Api {
  constructor (_axios) {
    super(_axios, '/kbis')
  }

  getCourt (alias, siteCloneId) {
    return this.get(`/${alias}`, {
      site_clone_id: siteCloneId
    })
  }
}

export default Courts
