import { PAYMENT_SUCCESS_STATUS } from '~/services/consts'

export const state = () => ({
  user: null
})

export const getters = {
  index (state) {
    return state.user
  },
  hasSubscription (state) {
    return state.user && state.user.status === PAYMENT_SUCCESS_STATUS
  }
}

export const actions = {}

export const mutations = {
  setUser (state, data) {
    state.user = data
  }
}
