<template>
  <div
    class="hovering-notification"
    :class="type"
    :style="variables"
    @touchstart="grab"
    @touchend="ungrab"
    @touchmove="grabbing"
    @mouseup="ungrab"
    @mousemove="grabbing"
    @mousedown="grab"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div class="icon">
      <CheckIcon v-if="type==='success'" />
      <InfoIcon v-else />
    </div>
    <div class="content-n-title">
      <h4 v-once>
        {{ title }}
      </h4>
      <div v-if="$slots.default" v-once class="content">
        <slot name="default" />
      </div>
      <div v-else v-once class="content" v-html="resContent" />
    </div>
    <button class="ok-button" @click="close">
      OK
    </button>
    <LoadBar ref="bar" mode="timer" class="bar" color="var(--svg-color)" />
  </div>
</template>
<script>
import LoadBar from '@/components/common/LoadBar.vue'
import InfoIcon from '@/assets/svg/icons/info.svg'
import CheckIcon from '@/assets/svg/icons/check-rounded-alt.svg'
import * as Animations from '@/services/animations'

export default {
  components: {
    LoadBar,
    InfoIcon,
    CheckIcon
  },
  props: {
    type: {
      default: 'info',
      validator (value) {
        return ['info', 'warning', 'error', 'success'].includes(value)
      }
    },
    title: {
      default: ''
    },
    content: {
      default: ''
    },
    timeToDie: {
      default: 5000 // ms
    }
  },
  data () {
    return {
      mouseInside: false,
      timeout: null,
      grabbed: false,
      grabOffset: 0,
      prevX: 0,
      resContent: ''
    }
  },
  computed: {
    variables () {
      return {
        '--offset': `${this.grabOffset}px`
      }
    }
  },
  created () {
    this.resContent = this.content
  },
  mounted () {
    if (this.timeToDie) {
      this.timeout = setTimeout(this.close, this.timeToDie)
      this.$refs.bar.startTimer(this.timeToDie)
    }
  },
  beforeDestroy () {
  },
  methods: {
    close () {
      this.$emit('close', this.grabOffset)
    },
    mouseEnter () {
      this.mouseInside = true
      this.$refs.bar.stopTimer()
      clearTimeout(this.timeout)
    },
    mouseLeave () {
      if (this.grabbed) return
      this.mouseInside = false
      if (this.timeToDie && this.$refs.bar) {
        this.$refs.bar.startTimer(this.timeToDie)
        this.timeout = setTimeout(this.close, this.timeToDie)
      }
    },
    grab (e) {
      this.grabbed = true
      const point = e.clientX || e.targetTouches[0].clientX
      this.prevX = point
      this.$refs.bar.stopTimer()
      clearTimeout(this.timeout)
    },
    ungrab () {
      this.grabbed = false
      if (Math.abs(this.grabOffset) > 60) this.close()
      else if (Math.abs(this.grabOffset) > 1) {
        this.ungrabAnimation = new Animations.NumericAnimation(
          this.grabOffset,
          0,
          500,
          state => this.grabOffset = state.value,
          () => {
            if (this && this.timeToDie && this.$refs.bar && !this.mouseInside) {
              this.$refs.bar.startTimer(this.timeToDie)
              this.timeout = setTimeout(this.close, this.timeToDie)
            }
          },
          null,
          null,
          null,
          null,
          null,
          [{ x: 0.54, y: 0.02 }, { x: 0.91, y: 1.025 }]
        )
        this.ungrabAnimation.start()
      }
    },
    grabbing (e) {
      if (this.grabbed) {
        if (this.ungrabAnimation) {
          this.ungrabAnimation.stop()
        }
        const point = e.clientX || e.targetTouches[0].clientX
        this.grabOffset += point - this.prevX

        this.prevX = point
      }
    }
  }
}
</script>
<style lang="scss">
.hovering-notification .icon.rouble {
  width: 18px;
  height: 18px;
  margin: 0 3px;
  display: inline-block;
  background-size: contain;
}
</style>
<style lang="scss" scoped>
.hovering-notification {
  max-width: clamp(416px, 450px, calc(100vw - 20px));
  min-width: min(100vw - 20px, 416px);
  width: 100%;
  --type-color: var(--title);
  --svg-color: var(--type-color);
  background: var(--white);
  color: var(--title);
  border-radius: 8px;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  position: relative;
  transform: translateX(var(--offset));
  word-wrap: anywhere;
  user-select: none;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08);
  font-family: Poppins, sans-serif;
  letter-spacing: 0.01em;
  align-items: center;

  &.warning {
    --type-color: var(--system-warning);
  }

  &.error {
    --type-color: var(--system-error);
  }

  &.info {
    --type-color: var(--title);
  }

  &.success {
    --type-color: var(--system-success-alt);
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: inherit;
    line-height: var(--line-height-normal-s1);
    font-weight: 500;
    font-size: var(--font-size-normal-s2);
  }

  .icon {
    @include flex-line-center;
    padding-left: 19px;
  }

  .bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
  }

  .content-n-title {
    padding: 10px 4px 10px 10px;

    h4 {
      font-weight: 700;
      font-size: var(--font-size-normal);
      line-height: var(--line-height-normal-l1);
      width: 100%;
      color: var(--type-color);
    }
  }

  .ok-button {
    height: max-content;
    position: relative;
    background: var(--separator);
    border-radius: 8px;
    margin: 8px;
    padding: 12px 18px;
    color: var(--title);
    transition: all 0.25s ease;
    font-weight: 600;
    font-size: var(--font-size-normal);
    line-height: var(--line-height-normal-l1);
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--brand);
    }
  }
}
</style>
