<template>
  <NuxtLink to="/" class="brand-logo">
    <p v-if="$store.getters['scripts/getMerchantName']" class="merchant-name">
      <span v-if="blue" class="blue">
        {{ blue }}
      </span>
      <span v-if="red" class="red">
        {{ red }}
      </span>
    </p>
    <Logo v-else class="brand-logo-svg" />
  </NuxtLink>
</template>

<script>
import Logo from '@/assets/svg/brand/brand-logo-simple.svg'

export default {
  name: 'BrandLogo',
  components: {
    Logo
  },
  computed: {
    parts () {
      return this.$store.getters['scripts/getMerchantNameParts']
    },
    name () {
      return this.$store.getters['scripts/getMerchantName']
    },
    blue () {
      return this.parts?.blue || this.name?.slice(0, 5)
    },
    red () {
      return this.parts?.red || this.name?.slice(5)
    }
  }
}
</script>
<style lang="scss" scoped>
.brand-logo {
  text-decoration: none;
  max-width: 114px;
  max-height: 34px;
  position: relative;

  .brand-logo-svg {
    max-width: 114px;
    max-height: 34px;
    width: 114px;
    height: 34px;
  }

  &::after {
    position: absolute;
    left: calc(50% + 23px);
    bottom: 3px;
    width: 0;
    transform: translateX(-50%);
    height: 1px;
    background: var(--hovered-brand);
    content: '';
    transition: all 0.25s ease;
  }

  &:focus-visible {
    color: var(--hovered-brand);
    outline: none;

    &::after {
      width: calc(69%);
    }
  }
}
.merchant-name {
  white-space: nowrap;
  display: flex;
  span {
    font-weight: 700;
    font-size: 18px;
  }
  .blue {
    text-transform: capitalize;
    color: var(--brand);
  }
  .red {
    color: var(--red);
  }
}
</style>
