<template>
  <a :href="`tel:${numberInner}`" class="phone-number-wrapper" :size="size">
    <PhoneIcon />
    <span class="phone-number">
      {{ number }}
    </span>
  </a>
</template>

<script>
import PhoneIcon from '@/assets/svg/icons/phone.svg'

export default {
  name: 'PhoneNumber',
  components: {
    PhoneIcon
  },
  props: {
    number: {
      default: '0800 94 77 57'
    },
    size: {
      default: 'normal',
      validator (value) {
        return ['normal', 'big'].includes(value)
      }
    }
  },
  computed: {
    numberInner () {
      let res = ''
      for (const x of this.number) {
        if (!isNaN(x) && x !== ' ') res += x
      }
      return res
    }
  }
}
</script>
<style lang="scss" scoped>
.phone-number-wrapper {
  --svg-color: var(--brand);
  padding: 4px;
  max-width: max-content;
  text-decoration: none;
  padding-left: 24px;
  position: relative;

  svg {
    position: absolute;
    top: 9px;
    left: 0px;
  }

  .phone-number {
    @include sm-normal-font;
    font-weight: 700;
    color: var(--title);
    white-space: nowrap;
    transition: all 0.25s ease;
  }

  &[size="big"] {
    padding-left: 28px;

    svg {
      height: 20px;
      width: 20px;
    }

    .phone-number {
      @include sm-big-font;
      --line-height: 27px;
    }
  }

  @include phone {
    &:focus,
    &:hover {
      color: var(--hovered-brand);
      --svg-color: currentColor;

      .phone-number {
        color: inherit;
      }
    }
  }

  &:active {
    color: var(--pressed-brand);
    --svg-color: currentColor;

    .phone-number {
      color: inherit;
    }
  }

  &:focus-visible {
    .phone-number::after {
      width: calc(100% + 10px);
    }

    outline: none;
  }

  .phone-number::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 0;
    height: 1px;
    background: var(--hovered-brand);
    content: "";
    transition: all 0.25s ease;
  }
}
</style>
