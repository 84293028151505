<template>
  <span class="header-link-wrapper" :class="`dropdown-${dropdownDirection}`" @click="linkClick" @keydown="keyDown">
    <template v-if="innerItems && innerItems.length">
      <SimpleDropdown
        ref="dropdown"
        :active="dropdownActive"
        class="header-links-dropdown-wrapper"
        :direction="dropdownDirection"
        :popover="!isMobile"
      >
        <template #activator>
          <div ref="root" class="link header-link" :tabindex="tabindex" @click="toggleDropdown">
            <slot name="default" />
            <Chevron
              style="margin-left:2px; margin-bottom:2px;"
              :style="{transform: `rotateZ(${dropdownActive?180:0}deg)`}"
            />
          </div>
        </template>
        <div class="header-links-dropdown">
          <HeaderLink
            v-for="(link,i) in innerItems"
            :key="i"
            :to="link.link"
            :tabindex="dropdownActive? '0':'-1'"
            @click="toggleDropdown"
          >
            {{ link.title }}
          </HeaderLink>
        </div>
      </SimpleDropdown>
    </template>
    <NuxtLink v-else ref="root" :to="{path: to, query: params}" class="link header-link" :tabindex="tabindex">
      <slot name="default" />
    </NuxtLink>
  </span>
</template>

<script>
import Chevron from '@/assets/svg/parts/small-filled-chevron-down.svg'
import deviceInfo from '@/mixins/deviceInfo'

export default {
  name: 'HeaderLink',
  components: {
    Chevron,
    SimpleDropdown: () => import('@/components/common/SimpleDropdown.vue')
  },
  mixins: [deviceInfo],
  props: {
    to: {
      default: null
    },
    innerItems: {
      default: () => []
    },
    dropdownDirection: {
      default: 'bottom',
      validator (value) {
        return ['top', 'bottom'].includes(value)
      }
    },
    tabindex: {
      default: 0
    },
    params: {
      default: null
    }
  },
  data () {
    return {
      dropdownActive: false
    }
  },
  mounted () {
    if (this.innerItems && this.innerItems.length) {
      document.addEventListener('click', this.checkHideDropdown)
      document.addEventListener('focusin', this.checkHideDropdownFocus)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.checkHideDropdown)
    document.removeEventListener('focusin', this.checkHideDropdownFocus)
  },
  methods: {
    checkHideDropdown (e) {
      const target = e.target.closest('.header-link.link')
      if (target !== this.$refs.root) {
        this.dropdownActive = false
      }
    },
    checkHideDropdownFocus (e) {
      if (this.$refs.dropdown) {
        const target = e.target.closest('.header-links-dropdown-wrapper')
        if (target !== this.$refs.dropdown.$el) {
          this.dropdownActive = false
        }
      }
    },
    toggleDropdown (e) {
      if (e.to === undefined && e.hasInnerItems === undefined) e.stopPropagation()
      else this.$emit('click', { to: e.to, hasInnerItems: e.hasInnerItems })
      this.dropdownActive = !this.dropdownActive
    },
    hideDropdown (e) {
      this.dropdownActive = false
    },
    linkClick (e) {
      e.stopPropagation()
      this.$emit('click', { to: this.to, hasInnerItems: this.innerItems && this.innerItems.length })
    },
    keyDown (e) {
      if (/escape/i.test(e.key)) {
        if (this.dropdownActive) {
          this.dropdownActive = false
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.link {
  text-decoration: none;
  color: var(--title);
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: all 0.25s ease;

  &:hover {
    color: var(--hovered-brand);
    outline: none;
  }

  &::after {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 0;
    transform: translateX(-50%);
    height: 1px;
    background: var(--hovered-brand);
    content: "";
    transition: all 0.25s ease;
  }

  &:focus-visible {
    color: var(--hovered-brand);
    outline: none;

    &::after {
      width: calc(100% + 5px);
    }
  }

  @include tablet-landscape {
    font-size: 14px;
  }

  @include phone {
    font-size: 15px;
  }
}

.header-link-wrapper {
  position: relative;
}

.header-links-dropdown {
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  border-radius: 10px;
  gap: 24px;
  background: var(--white);

  @include tablet-landscape {
    padding: 10px;
    position: fixed;
    border: 1px solid #E5E8EB;
    border-radius: 12px;
    margin-left: -45px;

    .link {
      text-transform: none;
    }
  }

  @include phone {
    padding: 18px 24px;
    position: initial;
    border: none;
    margin-left: 0px;
  }
}

.header-links-dropdown-wrapper {
  @include tablet-landscape {
    height: 20px;
  }
}
</style>
