import Api from './Api'

class Inpi extends Api {
  constructor (_axios) {
    super(_axios, '/inpiapi')
  }

  downloadAct (params) {
    return this.get('/download-documents', params)
  }
}

export default Inpi
