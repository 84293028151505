<template>
  <div class="error-page">
    <div class="title-n-description">
      <h1>{{ message }}</h1>
      <p>
        {{ comment }}
      </p>
      <component
        :is="$route.path==='/'?'a':'NuxtLink'"
        to="/"
        href="/"
        class="button-to-home-page"
      >
        <button>
          <HouseAlt /> <span>Retour à l'accueil</span>
        </button>
      </component>
    </div>
    <div class="status-code">
      <h2>{{ statusCode }}</h2>
    </div>
    <div class="search-block">
      <h2>Ou essayez à nouveau de trouver ce que vous voulez</h2>
      <SearchInput :design-variation="ERROR_PAGE_TYPE" class="search-input-error" @search="search" />
    </div>
  </div>
</template>

<script>
import SearchInput from '../components/forms/SearchInput'
import HouseAlt from '@/assets/svg/icons/house-alt.svg'
import deviceInfo from '~/mixins/deviceInfo'
import search from '~/mixins/search/search'
import { ERROR_PAGE_TYPE } from '~/services/consts'
import baseHeader from '~/mixins/baseHeader'

const messages = {
  401: 'Erreur d\'autorisation',
  404: 'Cette page n\'existe pas : (',
  500: 'Erreur interne du serveur',
  429: 'Trop de demandes'
}
const comments = {
  401: '',
  404: 'Mais rassurez-vous, nous en avons des centaines de milliers d\'autres alors vous devriez trouver votre bonheur !',
  500: 'Rafraîchir la page après un certain temps.',
  429: 'Rafraîchir la page après un certain temps.'
}

export default {
  components: {
    SearchInput,
    HouseAlt
  },
  mixins: [deviceInfo, search, baseHeader],
  props: {
    error: {
      default: undefined
    }
  },
  head () {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0'
        }
      ]
    }
  },
  computed: {
    statusCode () {
      return (this.error && +this.error.statusCode) || 500
    },
    message () {
      return messages[this.statusCode] || this.error.message || 'Error'
    },
    comment () {
      return comments[this.statusCode]
    },
    ERROR_PAGE_TYPE () {
      return ERROR_PAGE_TYPE
    }
  }
}
</script>

<style lang="scss" scoped>
.error-page {
  display: grid;
  grid-template-columns: 421px 1fr;
  grid-template-rows: 1fr 363px;
  max-width: 1210px;
  padding: 76px 20px 80px;
  color: var(--brand);
  font-family: Poppins, sans-serif;
  margin: 0 auto;
  font-weight: 500;
  gap: 20px 70px;

  .title-n-description {
    margin-top: 44px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    line-height: var(--line-height-normal-s1);
    font-size: var(--font-size-small);
  }
  p {
    color: #5974bc;
    max-width: 430px;
  }
  .status-code {
    font-size: 231px;
    line-height: 347px;
    font-weight: 600;
    letter-spacing: 0.1em;
    user-select: none;
    h2 {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }
  }
  h1 {
    font-weight: 600;
    line-height: var(--line-height-large-s2);
    font-size: var(--font-size-normal-l3);
    letter-spacing: 0.015em;
    color: inherit;
  }
  .button-to-home-page {
    text-decoration: none;
  }
  button {
    background: var(--brand-shade-5);
    border-radius: 10px;
    height: 61px;
    width: 235px;
    color: inherit;
    @include flex-line-center;
    cursor: pointer;
    transition: all 0.25s ease;

    span {
      margin-left: 10px;
      color: inherit;
      font-weight: 600;
      font-size: var(--font-size-normal-s2);
      line-height: var(--line-height-normal-s1);
      letter-spacing: 0.01em;
    }

    &:hover,
    &:focus {
      background: var(--brand-shade-4);
    }
  }

  .search-block {
    width: 100%;
    height: 363px;
    grid-column: 1 / 3;
    background: linear-gradient(265.9deg, #56b7e0 -1.61%, #5383ff 73.08%);
    border-radius: 40px;
    @include flex-line-center;
    flex-direction: column;
    gap: 20px;
    h2 {
      font-weight: 500;
      font-size: var(--font-size-large-s2);
      line-height: var(--line-height-large-s2-3);
      color: var(--white);
    }
    .search-input-error {
      max-width: 605px;
    }
  }
  @include tablet-landscape {
    & {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto auto;
      gap: 0;
      text-align: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 40px;
      position: relative;

      .search-block {
        max-width: 570px;
        padding-inline: 50px;
        margin-inline: auto;
        grid-column: 1;
        height: 230px;
        border-radius: 18px;
        gap: 16px;

        h2 {
          font-size: var(--font-size-normal-l1);
          line-height: var(--line-height-normal-l1-3);
        }
      }

      .status-code {
        order: -1;
        font-size: 40px;
        line-height: 60px;
        letter-spacing: 0.015em;
      }
      .title-n-description {
        align-items: center;
        margin-bottom: 40px;
        margin-top: 12px;
        gap: 0;

        p {
          margin-top: 8px;
          margin-bottom: 20px;
        }
      }
    }
  }
  @include phone {
    .search-block {
      padding-inline: 12px;
    }
  }
}
</style>
