<template>
  <nav class="links-container" :class="orientation">
    <HeaderLink
      v-for="(link,i1) in links"
      :key="i1"
      :to="link.link"
      :params="link.params"
      :inner-items="link.innerItems"
      :dropdown-direction="dropdownDirection"
      @click="linkClick"
    >
      {{ link.title }}
    </HeaderLink>
  </nav>
</template>

<script>
import HeaderLink from './HeaderLink.vue'
import { SEPARATED_MODE } from '~/store/filters/searchMode'

export default {
  name: 'LinksList',
  components: {
    HeaderLink
  },
  props: {
    links: {
      default: () => [
        { title: 'Entreprises', link: '/recherche', params: { targets: '0|', mode: SEPARATED_MODE } },
        { title: 'Dirigeants', link: '/recherche', params: { targets: '1|', mode: SEPARATED_MODE } },
        { title: 'Bénéficiaires', link: '/recherche', params: { targets: '2|', mode: SEPARATED_MODE } },
        {
          title: 'Services +',
          innerItems: [
            { title: 'Obtenir un Kbis', link: '/obtenir-kbis' },
            { title: 'Obtenir un n° de TVA', link: '/numero-tva-intracommunautaire' },
            { title: 'Obtenir un Avis SIRENE', link: '/avis-situation-sirene-insee' },
            { title: 'Obtenir un diagnostic financier', link: '/diagnostic-financier-nota-pme' }
          ]
        }
      ]
    },
    orientation: {
      type: String,
      default: 'horizontal',
      validator (value) {
        return ['horizontal', 'vertical'].includes(value)
      }
    }
  },
  computed: {
    dropdownDirection () {
      return 'bottom'
    }
  },
  methods: {
    linkClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.links-container {
  @include flex-line-center;
  @include very-small-font;
  gap: 20px;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
}
</style>
