<template>
  <div class="holder">
    <div ref="bar" :style="`background-color:${color}; width:0%; opacity: ${visible?'1': '0'}; height:${height}; ${barStyle}`" />
  </div>
</template>
<script>
export default {
  props: {
    progress: {
      default: 0// 0-100
    },
    color: {
      default: 'rgb(49, 209, 49)'
    },
    mode: {
      default: 'progress' // timer
    },
    height: {
      default: '100%'
    },
    barStyle: {
      default: ''
    }
  },
  data () {
    return {
      timerProgress: 0,
      visible: true
    }
  },
  watch: {
    progress (v) {
      try {
        this.$refs.bar.style.width = `${v}%`
      } catch {

      }
    }
  },
  created () {
    this.runtime = {}
  },
  methods: {
    setProgress (value) {
      try {
        this.$refs.bar.style.width = `${value}%`
      } catch {

      }
    },
    startTimer (time) {
      this.runtime.fullTime = time
      this.runtime.remainsTime = time
      this.__start()
      this.visible = true
    },
    stopTimer () {
      this.__stop()
      this.timerProgress = 0
      this.runtime.lastTime = 0
      this.$refs.bar.style.width = `${this.timerProgress}%`
    },
    resetTimer () {
      this.runtime.remainsTime = this.runtime.fullTime
      this.__start()
    },
    pauseTimer () {
      if (this.runtime.paused) {
        this.runtime.paused = false
        this.__start()
      } else {
        this.runtime.paused = true
        this.__stop()
      }
    },
    __start () {
      this.request = requestAnimationFrame(this.frame)
    },
    __stop () {
      cancelAnimationFrame(this.request)
    },
    frame (timeFromOrigin) {
      this.runtime.elapsed = timeFromOrigin - (this.runtime.lastTime || timeFromOrigin)
      this.timerProgress = (this.runtime.fullTime - this.runtime.remainsTime) / this.runtime.fullTime * 100

      try {
        this.$refs.bar.style.width = `${this.timerProgress}%`
      } catch {

      }
      this.runtime.remainsTime -= this.runtime.elapsed
      if (this.runtime.remainsTime <= 150 && this.visible) { this.visible = false }

      if (this.runtime.remainsTime <= 0) { this.__stop() } else { this.__start() }

      this.runtime.lastTime = timeFromOrigin
    }
  }
}
</script>
<style lang="scss" scoped>
.holder {
  width: 100%;
  position: relative;

  div {
    transition: opacity 0.25s ease-in-out;
  }
}
</style>
