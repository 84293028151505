<template>
  <button
    v-if="to === null"
    :disabled="disabled"
    :readonly="loading"
    class="cbutton"
    :class="buttonClasses"
    @click="click"
  >
    <span class="content" :class="{loading, 'first-loaded':firstLoaded}">
      <slot />
    </span>
    <span v-if="loading" class="loading-overlay">
      <SimpleSpinner size="30px" color="currentColor" thickness="4px" />
    </span>
  </button>
  <component
    :is="to.startsWith('http')?'a':'NuxtLink'"
    v-else
    :to="to"
    :href="to"
    :target="to.startsWith('http')?'_BLANK':undefined"
    :disabled="disabled"
    class="cbutton"
    :class="buttonClasses"
    @click="click"
  >
    <span class="content" :class="{loading, 'first-loaded':firstLoaded}">
      <slot />
    </span>
    <span v-if="loading" class="loading-overlay">
      <SimpleSpinner size="30px" color="currentColor" thickness="4px" />
    </span>
  </component>
</template>

<script>
import SimpleSpinner from './parts/SimpleSpinner.vue'

export default {
  name: 'CButton',
  components: { SimpleSpinner },
  props: {
    disabled: {
      required: false,
      default: false
    },
    borderType: {
      default: 'easy-rounded',
      validator (value) {
        return ['easy-rounded', 'rounded', 'minimum-rounded'].includes(value)
      }
    },
    black: {
      default: false
    },
    to: {
      default: null
    },
    loading: {
      default: false
    }
  },
  data () {
    return {
      firstLoaded: false
    }
  },
  computed: {
    buttonClasses () {
      const classes = [this.borderType]
      if (this.black) {
        classes.push('black')
      }
      if (this.loading) classes.push('loading')
      return classes
    }
  },
  watch: {
    loading (n) {
      if (!n && !this.firstLoaded) this.firstLoaded = true
    }
  },
  methods: {
    click (e) {
      if (this.loading) {
        e.stopPropagation()
        e.preventDefault()
        return false
      }
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  border-radius: inherit;
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  align-items: inherit;
  gap: inherit;
  width: inherit;

  &.first-loaded {
    animation: appearing 0.25s ease 0s 1 normal forwards;
  }

  &.loading {
    animation: disappearing 0.25s ease 0s 1 normal forwards;
  }
}

.cbutton {
  @include normal-font;
  -webkit-tap-highlight-color: transparent;
  padding: 14px 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    background-color: var(--hovered-brand)
  }

  &:active {
    transition-duration: 0.05s;
    background-color: var(--pressed-brand)
  }

  &:disabled {
    opacity: .3;
    pointer-events: none;
    cursor: auto;

    &.loading {
      background-color: var(--light-gray);
    }
  }

  &.loading {
    background-color: var(--pressed-brand);
    cursor: progress;
    pointer-events: none;
  }

  transition: all 0.25s ease;
  outline-offset: 10px;
  outline: 1px transparent solid;
}

a.cbutton {
  outline: none;
  border: none;
  background-color: var(--button-background);
  color: var(--button-foreground);
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.black {
  background: var(--title);

  &:hover {
    background: var(--title);
  }

  &:active {
    background: var(--title);
  }
}

.minimum-rounded {
  border-radius: 5px;
}

.easy-rounded {
  border-radius: 10px;
}

.rounded {
  border-radius: 50px
}

.loading-overlay {
  @include flex-line-center;
  animation: appearing 0.25s ease 0s 1 normal forwards;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  opacity: 0;
}

@keyframes appearing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappearing {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
