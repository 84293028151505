import * as defaultFilter from '@/services/defaultFilter.js'
import searchFiltersService from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters,
  mapper: state => value => searchFiltersService.mappers.prepareApe(value)

}
export const mutations = {
  ...defaultFilter.mutations

}
