import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters,
  mapper: state => value => value.trim(),
  validator: state => value => value && value.trim().length >= 3
}
export const mutations = {
  ...defaultFilter.mutations

}
