import Api from './Api'

class Beneficiaires extends Api {
  constructor (_axios) {
    super(_axios, '/beneficiaires')
  }

  search (params) {
    return this.post('/search', params)
  }

  graph (id, params, parentNameArr) {
    return this.getWithArrayOfObjs(`/${id}/graph`, params, parentNameArr)
  }
}

export default Beneficiaires
