import Api from './Api'

class Users extends Api {
  constructor (_axios) {
    super(_axios, '/users')
  }

  analyticsEventSent () {
    return this.put('/analytics-event-sent')
  }
}

export default Users
