<template>
  <portal v-if="!keepAlive" to="modals">
    <Transition
      :name="animationType"
      @before-enter="$emit('before-enter')"
      @after-enter="$emit('after-enter')"
      @after-leave="$emit('after-leave')"
      @before-leave="$emit('before-leave')"
    >
      <div
        v-if="visible"
        :style="variables"
        :class="custom ? 'custom' : 'basic-modal'"
      >
        <slot :params="params" :active="visible" />
      </div>
    </Transition>
  </portal>
  <Transition
    v-else
    :name="animationType"
    @before-enter="$emit('before-enter')"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @before-leave="$emit('before-leave')"
  >
    <div
      :style="variables"
      :class="[custom ? 'custom' : 'basic-modal']"
    >
      <slot :params="params" :active="keepAliveVisible" />
    </div>
  </Transition>
</template>

<script>
import modalNames from '~/modals/modalNames'
import { BASE_ANIMATION_TIME } from '~/components/modals/ModalRoot'

export const FADE = 'fade'
export const SLIDE_TOP = 'slide-top'
export const SLIDE_LEFT = 'slide-left'
export const ANIMATION_TYPES = [FADE, SLIDE_TOP, SLIDE_LEFT]

export default {
  name: 'CModal',
  inheritAttrs: false,
  props: {
    modalName: {
      required: true,
      validator: value => Object.values(modalNames).includes(value)
    },
    showTime: {
      default: BASE_ANIMATION_TIME,
      type: Number
    },
    hideTime: {
      default: BASE_ANIMATION_TIME,
      type: Number
    },
    custom: {
      default: false,
      type: Boolean
    },
    animationType: {
      default: FADE,
      validator: value => ANIMATION_TYPES.includes(value)
    },
    keepAlive: {
      default: false
    }
  },
  data () {
    return {
      params: {}
    }
  },
  computed: {
    variables () {
      return {
        '--show-time': `${this.showTime}ms`,
        '--hide-time': `${this.hideTime}ms`
      }
    },
    visible () {
      return this.$modals.openedModals.includes(this.modalName)
    },
    keepAliveVisible () {
      return this.$modals.keepAlive.current === this.modalName && this.$modals.openedModals.includes(this.modalName)
    }
  },
  created () {
    const { show, hide } = this
    this.$modals.subscribe(this.modalName, { show, hide })
  },
  destroyed () {
    const { show, hide } = this
    this.$modals.unsubscribe(this.modalName, { show, hide })
  },
  methods: {
    show (params) {
      if (Object.keys(params).length) this.params = params
      this.$emit('params-changed', params)
    },
    hide () {
      this.params = null
    }
  }
}
</script>

<style scoped lang="scss">
.basic-modal {
  @include centrify;
}

.custom {
  position: absolute;
  inset: 0;
}

.fade-enter-active {
  transition: opacity var(--show-time);
}

.fade-leave-active {
  transition: opacity var(--hide-time);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.slide-top-enter-active {
  transition: transform var(--show-time);
}
.slide-top-leave-active {
  transition: transform var(--hide-time);
}
.slide-top-enter,
.slide-top-leave-to {
  transform: translateY(-100%);
}
.slide-top-enter-to,
.slide-top-leave {
  transform: translateY(0);
}

.slide-left-enter-active {
  transition: transform var(--show-time);
}
.slide-left-leave-active {
  transition: transform var(--hide-time);
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(-100%);
}
.slide-left-enter-to,
.slide-left-leave {
  transform: translateX(0);
}
</style>
