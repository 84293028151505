const tempValue = '@_#$#*%('
export const state = () => ({
  scripts: null,
  merchantName: null,
  cloneId: null,
  seoSpinEnabled: null,
  redPart: null,
  bluePart: null
})

export const getters = {
  getScripts (state) {
    if (state.scripts) return JSON.parse(state.scripts.replaceAll(tempValue, 'gtag'))
    return null
  },
  getMerchantName (state) {
    if (state.merchantName?.toLowerCase() === 'docubiz') return null
    return state.merchantName
  },
  getMerchantNameParts (state) {
    const result = {
      blue: state.bluePart,
      red: state.redPart
    }
    if (!result.blue && !result.red) {
      return null
    }
    return result
  },
  getCloneId (state) {
    return state.cloneId
  },
  getSeoSpinEnabled (state) {
    return state.seoSpinEnabled
  }
}

export const actions = {
  loadScripts ({ commit, state }) {
    if (!this.$config.domain) return Promise.resolve()
    if (state.scripts) {
      return Promise.resolve(state.scripts)
    }
    return this.$api.siteClones.getByMerchantDomain(this.$config.domain)
      .then(res => {
        if (res) {
          let resString = JSON.stringify(res.analytics_scripts)
          resString = resString.replaceAll('gtag', tempValue)
          if (res.merchant_name) {
            commit('setMerchantName', {
              name: res.merchant_name,
              blue: res.merchant_name_blue,
              red: res.merchant_name_red
            })
          }
          if (res.id) commit('setCloneId', res.id)
          if (res.seo_spin_kbis_enabled) commit('setSeoSpinEnabled', res.seo_spin_kbis_enabled)
          if (res.analytics_scripts) {
            commit('setScripts', resString)
          }
          return res
        }
      })
      .catch(() => {})
  }
}

export const mutations = {
  setScripts (state, scripts) {
    state.scripts = scripts
  },
  setMerchantName (state, {
    name,
    blue,
    red
  }) {
    state.merchantName = name
    state.bluePart = blue
    state.redPart = red
  },
  setCloneId (state, id) {
    state.cloneId = id
  },
  setSeoSpinEnabled (state, value) {
    state.seoSpinEnabled = value
  }
}
