import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state()
})
export const getters = {
  ...defaultFilter.getters,
  mapper: state => value => value.id
}
export const mutations = {
  ...defaultFilter.mutations
}
