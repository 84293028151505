import * as defaultFilter from '@/services/defaultFilter.js'
import Service from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters,
  validator: state => value => (value.min && value.max && +value.min <= +value.max) || (value.min || value.max),
  mapper: state => value => Service.mappers.numberInterval(value, 'staff_count', { min: 'from', max: 'to' })

}
export const mutations = {
  ...defaultFilter.mutations

}
