import * as defaultFilter from '@/services/defaultFilter.js'
import Service from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters,
  encoder: state => value => Service.encoders.DateIntervalEncode(value, 'start', 'end'),
  decoder: state => value => Service.decoders.DateIntervalDecode(value, 'start', 'end'),
  validator: state => value => value.start <= value.end,
  mapper: state => value => Service.mappers.date(value, 'date_creation')

}
export const mutations = {
  ...defaultFilter.mutations

}
