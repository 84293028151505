<template>
  <div class="help help-main" :class="{ active }">
    <div
      v-for="(item, i) in items"
      :key="i"
      class="row"
      :class="{'row-active': selectIndex === i}"
      @click="$emit('click', i)"
    >
      <div v-html="highlighting(ucFirst(item), localSearchString)" />
      <NextIcon />
    </div>
    <div class="info">
      <hr>
      <span>Les infobulles ne prennent pas en compte les filtres</span>
    </div>
  </div>
</template>

<script>
import deviceInfo from '~/mixins/deviceInfo'
import NextIcon from '@/assets/svg/right-arrow-simple.svg'
import suggest from '~/mixins/suggest'

export default {
  components: {
    NextIcon
  },
  mixins: [deviceInfo, suggest],
  props: {
    q: {
      default: ''
    },
    active: {
      default: false
    },
    onLinkClick: {
      default: undefined
    },
    params: {
      default: null
    },
    items: {
      default: () => []
    },
    selectIndex: {
      default: -1
    }
  },
  data () {
    return {
      localSearchString: ''
    }
  },
  watch: {
    q () {
      if (this.selectIndex === -1) this.localSearchString = this.q
    }
  },
  methods: {
    _onLinkClick (item) {
      if (this.onLinkClick) {
        this.onLinkClick(item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  transform-origin: top;
  background: var(--white);
  overflow: hidden;
  border-radius: 16px;
  animation-duration: 0.25s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-name: rolling-vertical-out, disappearing;
  width: 100%;
  box-shadow: 0 6px 13px rgba(0, 0, 0, 0.05);
  padding: 10px 0px 20px 0px;

  --svg-color: black;

  &.active {
    animation-name: rolling-vertical-in, appearing;
  }

  .row {
    color: var(--title);
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include sm-normal-font;
    font-weight: 600;
    line-height: var(--line-height-normal-s1);
    padding: 10px 20px;

    & div {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover,
    &-active {
      background-color: var(--background-gray);
    }
  }

  .info {
    padding: 10px 20px 0px 20px;
    color: var(--light-gray);
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;

    hr {
      margin: 0;
      margin-bottom: 18px;
      background: var(--separator);
    }
  }
}

@keyframes rolling-vertical-in {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes rolling-vertical-out {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
</style>

<style lang="scss">
.help-main {
  .row {
    mark {
      color: var(--brand);
      text-decoration: unset !important;
    }
  }
}
</style>
