import Api from './Api'

class Services extends Api {
  constructor (_axios) {
    super(_axios)
  }

  paymentForm (params) {
    return this.post('/payment-form', params)
  }

  kbisFileRequest (params) {
    return this.post('/kbis-file-requests', params)
  }

  notaFileRequest (params) {
    return this.post('/nota-file-requests', params)
  }

  sireneFileRequest (params) {
    return this.post('/sirene-file-requests', params)
  }

  tvaRequest (params) {
    return this.post('/tva-request', params)
  }

  dropContactRequest (params) {
    return this.get('/dropcontact/info', params)
  }

  activities () {
    return this.get('/activities')
  }

  activity (alias) {
    return this.get(`/activities/${alias}`)
  }

  fullActivities () {
    return this.get('/full-activities')
  }

  counters () {
    return this.get('/main-info')
  }

  sendFiltersStatistic (filtersArray) {
    return this.post('/statistics/filters', { filters: filtersArray })
  }
}

export default Services
