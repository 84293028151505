import { setToken } from '~/plugins/axios'
import { setGTM, setLoginGTM } from '~/services/analytics'

const storageKey = {
  token: 'token'
}

const REPLACE_PATH = '/'

export const state = () => ({
  token: '',
  tokenType: 'Bearer'
})

export const getters = {
  token (state) {
    return state.token
  },
  tokenType (state) {
    return state.tokenType
  },
  isAuth (state, getters) {
    return getters.token.length !== 0
  }
}

export const actions = {
  async firstAuthCheck ({ getters, dispatch }) {
    if (getters.isAuth) {
      await dispatch('checkToken')
    } else setGTM(this.$config.production && !this.getters['device/isBot'], this.getters['scripts/getScripts'])
  },
  checkToken ({ getters, dispatch }) {
    setToken(getters.token, getters.tokenType)
    return dispatch('loadUser')
      .then(data => {
        setLoginGTM(data, this.$config.production && !this.getters['device/isBot'], this.getters['scripts/getScripts'])
      })
  },
  loadUser ({ commit }) {
    return this.$api.auth.me()
      .then(data => {
        commit('user/setUser', data, { root: true })
        return data
      })
      .catch(e => {
        console.log(e)
        this.$router.replace(REPLACE_PATH)
      })
  },
  loadInfoFromLocalStorage ({ commit }) {
    commit('changeInfoFromLocalStorage')
  },
  registration ({ getters, dispatch }, params) {
    const campaign = this.getters['statistics/campaign']
    const src = this.getters['statistics/index']
    const ads = this.getters['statistics/ads']

    if (src || campaign) params.source = `${src ?? ''} - ${campaign ?? ''}`.trim()

    if (ads) params.ads = JSON.stringify(ads)

    return this.$api.auth.registration(params).then(data => {
      setToken(data.token, getters.tokenType)
      this.$api.auth.me().then(userData => {
        dispatch('signIn', {
          user: userData,
          token: data.token
        })
      })
    })
  },
  login ({ dispatch, getters }, params) {
    return this.$api.auth.login(params).then(async data => {
      setToken(data.token, getters.tokenType)
      const userData = await this.$api.auth.me()
      dispatch('signIn', {
        user: userData,
        token: data.token
      })
    })
  },
  logout ({ dispatch }) {
    this.$api.auth.logout().then(() => {
      dispatch('signOut')
    })
  },
  signIn ({ commit }, data) {
    commit('changeToken', data.token)
    commit('user/setUser', data.user, { root: true })
    if ('localStorage' in window) {
      window.localStorage.setItem(storageKey.token, data.token)
    }
    if (this.$config.production) {
      window.dataLayer.push({
        userId: data.user.id,
        reference: data.user.reference,
        event: 'login'
      })
    }
  },
  async signOut ({ commit }) {
    commit('changeToken', '')
    commit('user/setUser', null, { root: true })
    setToken()
    if ('localStorage' in window) {
      window.localStorage.setItem(storageKey.token, '')
    }
    if (window?.dataLayer) {
      window.dataLayer.push(function () { this.set('reference') })
    }
  }
}

export const mutations = {
  changeInfoFromLocalStorage (state) {
    if (this.app.context.route.query.adminToken) {
      state.token = this.app.context.route.query.adminToken
    } else if ('localStorage' in window) {
      state.token = window.localStorage.getItem(storageKey.token) ?? ''
    }
  },
  changeToken (state, data) {
    state.token = data
  }
}
