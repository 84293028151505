import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a455d740 = () => interopDefault(import('../pages/abo-premium-kbis.vue' /* webpackChunkName: "pages/abo-premium-kbis" */))
const _3eb15870 = () => interopDefault(import('../pages/abo-premium-nota-pme.vue' /* webpackChunkName: "pages/abo-premium-nota-pme" */))
const _6b8604df = () => interopDefault(import('../pages/abo-premium-sirene.vue' /* webpackChunkName: "pages/abo-premium-sirene" */))
const _7d961260 = () => interopDefault(import('../pages/abo-premium-tva.vue' /* webpackChunkName: "pages/abo-premium-tva" */))
const _76b9b13c = () => interopDefault(import('../pages/annuaire/index.vue' /* webpackChunkName: "pages/annuaire/index" */))
const _93fd8d38 = () => interopDefault(import('../pages/avis-situation-sirene-insee.vue' /* webpackChunkName: "pages/avis-situation-sirene-insee" */))
const _06b7b198 = () => interopDefault(import('../pages/cgv.vue' /* webpackChunkName: "pages/cgv" */))
const _2fa06b5f = () => interopDefault(import('../pages/confirmation-de-kbis.vue' /* webpackChunkName: "pages/confirmation-de-kbis" */))
const _4ee11347 = () => interopDefault(import('../pages/confirmation-de-nota-pme.vue' /* webpackChunkName: "pages/confirmation-de-nota-pme" */))
const _10326a16 = () => interopDefault(import('../pages/confirmation-de-paiement-kbis.vue' /* webpackChunkName: "pages/confirmation-de-paiement-kbis" */))
const _71bc9046 = () => interopDefault(import('../pages/confirmation-de-paiement-nota-pme.vue' /* webpackChunkName: "pages/confirmation-de-paiement-nota-pme" */))
const _2e24f7d3 = () => interopDefault(import('../pages/confirmation-de-paiement-premium.vue' /* webpackChunkName: "pages/confirmation-de-paiement-premium" */))
const _78046a34 = () => interopDefault(import('../pages/confirmation-de-paiement-sirene.vue' /* webpackChunkName: "pages/confirmation-de-paiement-sirene" */))
const _3f77999b = () => interopDefault(import('../pages/confirmation-de-paiement-tva.vue' /* webpackChunkName: "pages/confirmation-de-paiement-tva" */))
const _b050cfc4 = () => interopDefault(import('../pages/confirmation-de-sirene.vue' /* webpackChunkName: "pages/confirmation-de-sirene" */))
const _0199781e = () => interopDefault(import('../pages/confirmation-de-tva.vue' /* webpackChunkName: "pages/confirmation-de-tva" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _db852bd2 = () => interopDefault(import('../pages/diagnostic-financier-nota-pme.vue' /* webpackChunkName: "pages/diagnostic-financier-nota-pme" */))
const _79a8b8e6 = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _7c34b906 = () => interopDefault(import('../pages/numero-tva-intracommunautaire.vue' /* webpackChunkName: "pages/numero-tva-intracommunautaire" */))
const _5a47ffa5 = () => interopDefault(import('../pages/obtenir-kbis.vue' /* webpackChunkName: "pages/obtenir-kbis" */))
const _1af88c15 = () => interopDefault(import('../pages/paiement.vue' /* webpackChunkName: "pages/paiement" */))
const _9d61a446 = () => interopDefault(import('../pages/premium.vue' /* webpackChunkName: "pages/premium" */))
const _bf43964c = () => interopDefault(import('../pages/premium-subscribe.vue' /* webpackChunkName: "pages/premium-subscribe" */))
const _278d9ffe = () => interopDefault(import('../pages/recherche.vue' /* webpackChunkName: "pages/recherche" */))
const _2270b7b8 = () => interopDefault(import('../pages/telechargement-kbis.vue' /* webpackChunkName: "pages/telechargement-kbis" */))
const _13beec40 = () => interopDefault(import('../pages/annuaire/_region/index.vue' /* webpackChunkName: "pages/annuaire/_region/index" */))
const _77091230 = () => interopDefault(import('../pages/entreprise/_entreprise.vue' /* webpackChunkName: "pages/entreprise/_entreprise" */))
const _581c3f15 = () => interopDefault(import('../pages/annuaire/_region/_departement/index.vue' /* webpackChunkName: "pages/annuaire/_region/_departement/index" */))
const _a4b1129e = () => interopDefault(import('../pages/annuaire/_region/_departement/_commune/index.vue' /* webpackChunkName: "pages/annuaire/_region/_departement/_commune/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _83d8767c = () => interopDefault(import('../pages/_landing.vue' /* webpackChunkName: "pages/_landing" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/abo-premium-kbis",
    component: _a455d740,
    name: "abo-premium-kbis"
  }, {
    path: "/abo-premium-nota-pme",
    component: _3eb15870,
    name: "abo-premium-nota-pme"
  }, {
    path: "/abo-premium-sirene",
    component: _6b8604df,
    name: "abo-premium-sirene"
  }, {
    path: "/abo-premium-tva",
    component: _7d961260,
    name: "abo-premium-tva"
  }, {
    path: "/annuaire",
    component: _76b9b13c,
    name: "annuaire"
  }, {
    path: "/avis-situation-sirene-insee",
    component: _93fd8d38,
    name: "avis-situation-sirene-insee"
  }, {
    path: "/cgv",
    component: _06b7b198,
    name: "cgv"
  }, {
    path: "/confirmation-de-kbis",
    component: _2fa06b5f,
    name: "confirmation-de-kbis"
  }, {
    path: "/confirmation-de-nota-pme",
    component: _4ee11347,
    name: "confirmation-de-nota-pme"
  }, {
    path: "/confirmation-de-paiement-kbis",
    component: _10326a16,
    name: "confirmation-de-paiement-kbis"
  }, {
    path: "/confirmation-de-paiement-nota-pme",
    component: _71bc9046,
    name: "confirmation-de-paiement-nota-pme"
  }, {
    path: "/confirmation-de-paiement-premium",
    component: _2e24f7d3,
    name: "confirmation-de-paiement-premium"
  }, {
    path: "/confirmation-de-paiement-sirene",
    component: _78046a34,
    name: "confirmation-de-paiement-sirene"
  }, {
    path: "/confirmation-de-paiement-tva",
    component: _3f77999b,
    name: "confirmation-de-paiement-tva"
  }, {
    path: "/confirmation-de-sirene",
    component: _b050cfc4,
    name: "confirmation-de-sirene"
  }, {
    path: "/confirmation-de-tva",
    component: _0199781e,
    name: "confirmation-de-tva"
  }, {
    path: "/contact",
    component: _e64111b4,
    name: "contact"
  }, {
    path: "/diagnostic-financier-nota-pme",
    component: _db852bd2,
    name: "diagnostic-financier-nota-pme"
  }, {
    path: "/mentions-legales",
    component: _79a8b8e6,
    name: "mentions-legales"
  }, {
    path: "/numero-tva-intracommunautaire",
    component: _7c34b906,
    name: "numero-tva-intracommunautaire"
  }, {
    path: "/obtenir-kbis",
    component: _5a47ffa5,
    name: "obtenir-kbis"
  }, {
    path: "/paiement",
    component: _1af88c15,
    name: "paiement"
  }, {
    path: "/premium",
    component: _9d61a446,
    name: "premium"
  }, {
    path: "/premium-subscribe",
    component: _bf43964c,
    name: "premium-subscribe"
  }, {
    path: "/recherche",
    component: _278d9ffe,
    name: "recherche"
  }, {
    path: "/telechargement-kbis",
    component: _2270b7b8,
    name: "telechargement-kbis"
  }, {
    path: "/annuaire/:region",
    component: _13beec40,
    name: "annuaire-region"
  }, {
    path: "/entreprise/:entreprise?",
    component: _77091230,
    name: "entreprise-entreprise"
  }, {
    path: "/annuaire/:region/:departement",
    component: _581c3f15,
    name: "annuaire-region-departement"
  }, {
    path: "/annuaire/:region/:departement/:commune",
    component: _a4b1129e,
    name: "annuaire-region-departement-commune"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:landing",
    component: _83d8767c,
    name: "landing"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
