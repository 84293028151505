// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*,:after,:before{box-sizing:border-box;scroll-behavior:smooth;-webkit-tap-highlight-color:rgba(0,0,0,0)}@media(prefers-reduced-motion){html[focus-within]{scroll-behavior:auto}html:focus-within{scroll-behavior:auto}}ol[class],ul[class]{padding:0}blockquote,body,dd,dl,figcaption,figure,h1,h2,h3,h4,li,ol[class],p,ul[class]{margin:0}body{scroll-behavior:smooth;text-rendering:optimizeSpeed;line-height:1.5}ol[class],ul[class]{list-style:none}a:not([class]){-webkit-text-decoration-skip:ink;text-decoration-skip-ink:auto}img{max-width:100%;display:block}button,input,select,textarea{font:inherit}input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration,input[type=search]::-webkit-search-results-button,input[type=search]::-webkit-search-results-decoration{display:none!important;visibility:hidden!important}input[type=search]::-ms-clear,input[type=search]::-webkit-autofill,input[type=search]::-webkit-contacts-auto-fill-button,input[type=search]::-webkit-credentials-auto-fill-button,input[type=search]::-webkit-search-cancel-button{display:none!important;visibility:hidden!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet-landscape": "1160px",
	"tablet-portrait": "900px",
	"phone": "640px"
};
module.exports = ___CSS_LOADER_EXPORT___;
