import * as defaultFilter from '@/services/defaultFilter.js'
import Service from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters,
  encoder: state => value => Service.encoders.AgesOrDatesOfBirthIntervalEncode(value),
  decoder: state => value => Service.decoders.AgesOrDatesOfBirthIntervalDecode(value),
  validator: state => value => Service.validators.ageOrDateOfBirth(value),
  mapper: state => value => Service.mappers.ageOrDateOfBirth(value, 'beneficiaire')
}
export const mutations = {
  ...defaultFilter.mutations

}
