import Api from './Api'

class Search extends Api {
  constructor (_axios) {
    super(_axios, '/search')
  }

  multiple (params) {
    return this.get('/multiple', params)
  }

  filters () {
    return this.get('/filters')
  }

  documents (params) {
    return this.post('/documents', params)
  }

  combinedSearch (params) {
    return this.post('/legal-unites-and-representants-and-beneficiaires', params)
  }

  suggest (params) {
    return this.get('/suggest', params)
  }
}

export default Search
