import { ucFirst } from '~/services/helpers'

export default {
  methods: {
    ucFirst,
    highlighting (value, q) {
      return `<mark>${value.substr(0, q.length)}</mark>${value.substr(q.length)}`
    }
  }
}
