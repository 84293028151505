import Api from './Api'

class Requests extends Api {
  constructor (_axios) {
    super(_axios, '/requests')
  }

  create (params) {
    return this.post('/', params)
  }
}

export default Requests
