<template>
  <div ref="track" class="track" :style="variables">
    <HoveringNotification
      v-for="(notif, i) in notifications"
      :key="notif.n"
      ref="notif"
      class="notif-item"
      :title="notif.title"
      :content="notif.content"
      :type="notif.type"
      :time-to-die="notif.timeToDie"
      :style="`--height:var(--item-height-${i})`"
      @close="(e) => close(i, e)"
    />
  </div>
</template>
<script>
import HoveringNotification from './HoveringNotification.vue'

export default {
  components: { HoveringNotification },
  props: {
    addNotificationEvent: {
      default: 'notification'
    },
    addNotificationErrorEvent: {
      default: 'notification-error'
    },
    addNotificationWarningEvent: {
      default: 'notification-warning'
    },
    addNotificationSuccessEvent: {
      default: 'notification-success'
    }
  },
  data () {
    return {
      itemHeights: [],
      notifications: [],
      animationTime: 350,
      incrementer: 0
    }
  },
  computed: {
    variables () {
      const res = {
        '--animation-time': `${this.animationTime}ms`
      }
      for (let i = 0; i < this.itemHeights.length; i++) {
        res[`--item-height-${i}`] = `${this.itemHeights[i]}px`
      }
      return res
    },
    extraNotifications () {
      return this.$store.getters['snackbar/getNotifications']
    }
  },
  watch: {
    async extraNotifications () {
      const { length } = this.extraNotifications
      let i = 0
      while (i < length) {
        this.addNotificationHandler(await this.$store.dispatch('snackbar/popNotification'))
        i++
      }
    }
  },
  mounted () {
    this.$root.$on(this.addNotificationEvent, this.addNotificationHandler)
    this.$root.$on(this.addNotificationErrorEvent, this.addError)
    this.$root.$on(this.addNotificationWarningEvent, this.addWarning)
    this.$root.$on(this.addNotificationSuccessEvent, this.addSuccess)
  },
  beforeDestroy () {
    this.$root.$off(this.addNotificationEvent, this.addNotificationHandler)
    this.$root.$off(this.addNotificationErrorEvent, this.addError)
    this.$root.$off(this.addNotificationWarningEvent, this.addWarning)
    this.$root.$off(this.addNotificationSuccessEvent, this.addSuccess)
  },
  methods: {
    unique () {
      return performance.now()
    },
    close (index, grabOffset) {
      if (grabOffset < 0) {
        this.$refs.track.children[index].classList.add('removing-left')
      } else if (grabOffset > 0) {
        this.$refs.track.children[index].classList.add('removing-right')
      } else if (Math.random() > 0.49999999) {
        this.$refs.track.children[index].classList.add('removing-right')
      } else {
        this.$refs.track.children[index].classList.add('removing-left')
      }

      setTimeout(() => {
        this.itemHeights.splice(index, 1)
        this.notifications.splice(index, 1)
      }, this.animationTime)
    },
    addError (params) {
      this.addNotificationHandler({
        title: params.title || 'Erreur',
        content: params.content || '',
        timeToDie: params.timeToDie || 5000,
        type: 'error'
      })
    },
    addWarning (params) {
      this.addNotificationHandler({
        title: params.title || 'Avertissement',
        content: params.content || '',
        timeToDie: params.timeToDie || 5000,
        type: 'warning'
      })
    },
    addSuccess (params) {
      this.addNotificationHandler({
        title: params.title || 'Succès',
        content: params.content || '',
        timeToDie: params.timeToDie || 5000,
        type: 'success'
      })
    },
    addNotificationHandler (params) {
      this.addNotification(
        params.title || 'Info',
        params.content,
        params.type || 'info',
        params.timeToDie || 5000
      )

      this.$nextTick(() => {
        if (!this.$refs.track) return
        const item = this.$refs.track.children[0]
        this.itemHeights.unshift(item.getBoundingClientRect().height)
        item.classList.add('adding')
        setTimeout(() => item.classList.remove('adding'), this.animationTime)
      })
    },
    addNotification (title, content, type, timeToDie = 10000) {
      this.notifications.push({
        title,
        content,
        type,
        n: this.incrementer++,
        timeToDie
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.track {
  --gap: 20px;
  position: fixed;
  top: 0;
  max-height: 300px;
  min-height: 300px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 102;
  padding: var(--gap) 500px 0px;
  //background: #ffffff22;
  display: flex;
  --local-gap: var(--gap);

  > * {
    --margin-top: calc(var(--local-gap) / 2);
    --margin-bottom: calc(var(--local-gap) / 2);
    margin-top: var(--margin-top);
    margin-bottom: var(--margin-bottom);
  }
  & > *:first-child {
    --margin-top: 0;
  }
  & > *:last-child {
    --margin-bottom: 60px;
  }
  flex-direction: column;
  align-items: center;
  overflow: auto;
  mask-image: linear-gradient(
    transparent 0%,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 80%,
    transparent
  );
  pointer-events: none;

  .notif-item {
    pointer-events: all;
    flex-shrink: 0;
    width: 100%;
    &.adding {
      animation: slide-in var(--animation-time) ease-in-out 0s 1 normal
        backwards;
    }
    &.removing-left {
      animation: slide-out-left var(--animation-time) ease-in-out 0s 1 normal
        forwards;
      pointer-events: none;
    }
    &.removing-right {
      animation: slide-out-right var(--animation-time) ease-in-out 0s 1 normal
        forwards;
      pointer-events: none;
    }
  }

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@keyframes slide-in {
  0% {
    margin-top: calc(0px - var(--height) - var(--gap));
    opacity: 0;
  }
  100% {
    margin-top: 0px;
    opacity: 1;
  }
}
@keyframes slide-out-left {
  0% {
    transform: translateX(var(--offset, 0%));
    margin-bottom: 0;
    opacity: 1;
  }
  100% {
    transform: translateX(calc(-150% + var(--offset)));
    margin-bottom: calc(0px - var(--margin-bottom) - var(--height));
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    transform: translateX(var(--offset, 0%));
    margin-bottom: 0;
    opacity: 1;
  }
  100% {
    transform: translateX(calc(150% + var(--offset)));
    margin-bottom: calc(0px - var(--margin-bottom) - var(--height));
    opacity: 0;
  }
}
</style>
