const service = {
  aliases: {
    capital: 'cap',
    rapidFilters: 'rf',
    dirigeantAge: 'da',
    dirigeantStatus: 'ds',
    beneficiaireAge: 'ba',
    beneficiaireStatus: 'bs',
    beneficiairePowers: 'bp',
    beneficiairePowersMode: 'bm',
    beneficiaireId: 'bi',
    closingDate: 'cld',
    turnover: 'trn',
    netIncome: 'ni',
    creditRate: 'cr',
    returnOnEquity: 're',
    placement: 'pl',
    siren: 's',
    formJuridique: 'fj',
    category: 'cat',
    statusRCS: 'rcs',
    status: 'st',
    creationDate: 'crd',
    employees: 'guy',
    collectiveProcedures: 'cp',
    priorities: 'pr',
    establishments_count: 'etc',
    net_profit: 'prft',
    approximative: 'appr',
    precision: 'prec',
    searchMode: 'mode',
    emplacement: 'emp',
    representantId: 'ri',
    address: 'adds'
  },
  unaliases: {},
  backendAliases: {
    precision: 'approximative',
    priorities: 'radar',
    statusRCS: 'rcs_status',
    category: 'categorie_entreprise',
    APECode: 'ape_code',
    formJuridique: 'form_juridique',
    status: 'lu_status',
    dirigeantStatus: 'qualites',
    beneficiaireStatus: 'provisions_of_beneficiaires',
    collectiveProcedures: 'collective_procedure_types',
    beneficiaireId: 'beneficiaire_id',
    representantId: 'representant_id'
  },
  alias (name) {
    if (service.aliases[name] !== undefined) return service.aliases[name]
    return name
  },
  aliasBackend (name) {
    if (service.backendAliases[name] !== undefined) return service.backendAliases[name]
    return name
  },
  unalias (alias) {
    if (service.unaliases[alias] !== undefined) return service.unaliases[alias]
    return alias
  },
  encoders: {
    boolean (value, prefix) {
      const res = {}
      if (value) {
        res[service.alias(prefix)] = 1
      }
      return res
    },
    DateIntervalEncode (value, fieldNameFrom = 'from', fieldNameTo = 'to') {
      const res = {}
      if (value) {
        if (value[fieldNameFrom]) res[fieldNameFrom] = service.encoders.DateEncode(value[fieldNameFrom])
        if (value[fieldNameTo]) res[fieldNameTo] = service.encoders.DateEncode(value[fieldNameTo])
      }
      return res
    },
    AgesOrDatesOfBirthIntervalEncode (value) {
      if (value === null) return null
      let res = {}
      if (value.mode === 'date') {
        res = service.encoders.DateIntervalEncode(value)
      } else if (value.mode === 'age') {
        for (const x of ['from', 'to']) {
          if (value[x] !== null && value[x] !== undefined) {
            res[x] = value[x]
          }
        }
      }
      return res
    },
    DateEncode (value) {
      return new Date(value).toISOString()
        .split('T')[0]
    },
    ArrayEncode (value) {
      return `${JSON.stringify(value)
        .replaceAll(/[\]["]/g, '')
        .replaceAll(',', '|')}|`
    },
    TurnOutObject (value, prefix) {
      if (typeof value !== 'object') {
        return value.toString()
      }
      let res = {}
      for (const field in value) {
        let alias = service.alias(field)
        if (prefix) {
          alias = `${prefix}_${alias}`
        }
        if (value[field] === null) continue

        if (typeof value[field] === 'object' && !(value[field] instanceof Array)) {
          if (value[field] instanceof Date) {
            res[alias] = value[field].toISOString()
              .split('T')[0]
          }
          res = { ...res, ...this.TurnOutObject(value[field], alias) }
        } else {
          res[alias] = value[field]
        }
      }
      return res
    }
  },
  decoders: {
    boolean (value, prefix) {
      let res = null
      const index = service.unalias(prefix)
      if (+value[index] || +value > 0) {
        res = true
      } else if (+value[index] || +value === 0) {
        res = false
      }
      return res
    },
    DateIntervalDecode (value, fieldNameFrom = 'from', fieldNameTo = 'to') {
      const res = {}
      if (value) {
        if (value[fieldNameFrom]) res[fieldNameFrom] = service.decoders.DateDecode(value[fieldNameFrom])
        if (value[fieldNameTo]) res[fieldNameTo] = service.decoders.DateDecode(value[fieldNameTo])
      }
      return res
    },
    AgesOrDatesOfBirthIntervalDecode (value) {
      if (value.from && value.from.includes('-') || value.to && value.to.includes('-')) {
        const res = { mode: 'date' }
        if (value.from) {
          res.from = service.decoders.DateDecode(value.from)
        }
        if (value.to) {
          res.to = service.decoders.DateDecode(value.to)
        }
        return res
      }
      return {
        ...value,
        mode: 'age'
      }
    },
    DateDecode (value) {
      return new Date(value)
    },
    ArrayDecode (value) {
      const res = []
      for (const x of value.split('|')) {
        if (x.length) res.push(x)
      }
      return res
    },
    TurnInObject (value) {
      if (typeof value !== 'object') {
        if (typeof value === 'string') {
          if (value.includes('|')) {
            return service.decoders.ArrayDecode(value)
          }
        }
        return value
      }
      const map = new Map()
      for (const i in value) {
        const index = i.indexOf('_')
        if (index === -1) {
          map.set(i, value[i])
        } else {
          const first = i.substring(0, index)
          const second = i.substring(index + 1)
          if (!map.has(first)) {
            map.set(first, {})
          }
          map.get(first)[second] = value[i]
        }
      }
      const res = {}
      for (const [key, value] of map) {
        const alias = service.unalias(key)
        if (typeof value === 'object') {
          res[alias] = this.TurnInObject(value)
        } else if (typeof value === 'string' && value.includes('|')) {
          res[alias] = service.decoders.ArrayDecode(value)
        } else {
          res[alias] = value
        }
      }
      return res
    }
  },
  mappers: {
    boolean (value) {
      if (value) {
        return 1
      }
    },
    ageOrDateOfBirth (value, prefix) {
      const res = {}
      Object.defineProperty(res, 'expand', {
        value: true,
        enumerable: false,
        writable: false
      })
      if (value.mode === 'date') {
        if (value.from) res[`${prefix}_date_of_birth_from`] = service.encoders.DateEncode(value.from)
        if (value.to) res[`${prefix}_date_of_birth_to`] = service.encoders.DateEncode(value.to)
      } else if (value.mode === 'age') {
        if (value.from !== undefined && value.from !== null) res[`${prefix}_age_from`] = value.from
        if (value.to !== undefined && value.to !== null) res[`${prefix}_age_to`] = value.to
      }
      return res
    },
    date (value, prefix) {
      const res = {}
      Object.defineProperty(res, 'expand', {
        value: true,
        enumerable: false,
        writable: false
      })
      const from = value.from || value.start
      const to = value.to || value.end
      if (from) res[`${prefix}_from`] = service.encoders.DateEncode(from)
      if (to) res[`${prefix}_to`] = service.encoders.DateEncode(to)

      return res
    },
    numberInterval (value, prefix, valueToResultFields = {
      from: 'min',
      to: 'max'
    }) {
      const res = {}
      Object.defineProperty(res, 'expand', {
        value: true,
        enumerable: false,
        writable: false
      })
      if (value) {
        for (const i in valueToResultFields) {
          if (value[i]) res[`${prefix}_${valueToResultFields[i]}`] = value[i]
        }
      }
      return res
    },
    prepareApe (value) {
      if (!Array.isArray(value)) {
        return null
      }
      if (!value || !value.length) {
        return null
      }
      const response = []
      value.forEach(item => {
        const tempValue = item.split('-')
        const newItem = { nomenclature: tempValue[0] }
        tempValue[1] && (newItem.domain = tempValue[1])
        tempValue[2] && (newItem.code = tempValue[2])
        response.push(newItem)
      })
      return response
    },
    localIdToStrings (value, prefix, backendValues) {
      const res = []
      for (const item in value) {
        const found = backendValues.find(x => x.id === item)
        if (found) {
          res.push(found.title.toLowerCase())
        }
      }
      const temp = {}
      if (res.length) {
        temp[prefix] = res
      }
      return temp
    },

    object (value, prefix) {
      const result = {}
      for (const i in value) {
        result[`${prefix}[${i}]`] = value[i]
      }
      return result
    }
  },
  validators: {
    ageOrDateOfBirth (value) {
      if (value.from == undefined || value.from == null || value.to == undefined || value.to == null) return true
      if (value.mode === 'age') {
        return +value.from <= +value.to
      }
      if (value.mode === 'date') {
        return value.from <= value.to
      }
    },
    numberInterval (value) {
      return (value.from && value.to ? +value.from <= +value.to : true)
    }
  }

}
service.all = {
  ...service.finances,
  ...service.persons,
  ...service.base
}
for (const x in service.aliases) {
  service.unaliases[service.aliases[x]] = x
}

export default service
