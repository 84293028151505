import Api from './Api'

class Geo extends Api {
  constructor (_axios) {
    super(_axios, '/geo')
  }

  mass (params) {
    return this.post('/mass', params)
  }

  getItems (params) {
    return this.get('', params)
  }

  getForType (type, params) {
    const { limit, offset } = params
    return this.get(`/type/${type}`, { limit, offset })
  }

  search (params) {
    return this.get('/search', params)
  }

  searchForType (type, params) {
    const {
      query, limit, offset
    } = params
    return this.get(`/search/${type}`, { query, limit, offset })
  }

  regions (params) {
    return this.get('/regions', params)
  }

  region (alias) {
    return this.get(`/regions/${alias}`)
  }

  departements (region) {
    if (region) return this.get(`/regions/${region}/departements`)
    return this.get('/departements')
  }

  departement (code) {
    return this.get(`/departements/${code}`)
  }

  communes (departement) {
    return this.get(`/departements/${departement}/communes`)
  }

  commune (commune) {
    return this.get(`/communes/${commune}`)
  }

  companies (commune, params) { // limit&offset
    return this.get(`/${commune}/companies`, params)
  }
}

export default Geo
