<template>
  <div
    class="modal-holder"
    :class="{active:modalActive}"
  >
    <Transition
      name="fade"
      appear
      @before-enter="beforeEnter"
      @after-leave="afterLeave"
      @leave-cancelled="leaveCancelled"
    >
      <div
        v-show="isActive"
        :id="OVERLAY_ID"
        :style="variables"
        class="__modal-root"
        @mouseup="mouseup"
        @mousedown="mousedown"
      />
    </Transition>
    <portal-target name="modals" multiple />
  </div>
</template>

<script>

export const BASE_ANIMATION_TIME = 250
export const OVERLAY_ID = 'modal_overlay'
export const SCREEN_SIZE_CHECKER_ID = 'screen-size-checker'

export default {
  name: 'ModalRoot',
  data () {
    return {
      showTime: BASE_ANIMATION_TIME,
      hideTime: BASE_ANIMATION_TIME,
      targetClick: false,
      OVERLAY_ID,
      modalActive: false
    }
  },
  computed: {
    isActive () {
      return this.$modals.anyModal
    },
    variables () {
      return {
        '--bg-show-time': `${this.showTime}ms`,
        '--bg-hide-time': `${this.hideTime}ms`
      }
    }
  },
  watch: {
    isActive (v) {
      if (v) this.updateScrollWidth()
    }
  },
  mounted () {
    this.mountSizeChecker()
    this.resizeHandler()

    window.addEventListener('keydown', this.onKeyPress)
    window.addEventListener('resize', this.resizeHandler)

    requestAnimationFrame(() => this.updateScrollWidth())
  },
  destroyed () {
    window.removeEventListener('keydown', this.onKeyPress)
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    beforeEnter () {
      const body = document.querySelector('body')
      this.__scrollPosition = `${-window.pageYOffset}px`
      body.style.overflow = 'hidden'
      body.style.position = 'fixed'
      body.style.top = this.__scrollPosition
      body.style.width = 'calc(100vw - var(--scroll-width))'
      this.modalActive = true
    },
    afterLeave () {
      const body = document.querySelector('body')
      body.style.removeProperty('overflow')
      body.style.removeProperty('position')
      body.style.removeProperty('top')
      body.style.removeProperty('width')
      window.scrollTo({
        top: -parseInt(this.__scrollPosition, 10),
        behavior: 'instant'
      })
      this.modalActive = false
    },
    leaveCancelled () {
      this.afterLeave()
    },
    mountSizeChecker () {
      const screenSizeChecker = document.createElement('div')
      screenSizeChecker.setAttribute('id', SCREEN_SIZE_CHECKER_ID)
      screenSizeChecker.classList.add('screen-size-checker')
      document.body.appendChild(screenSizeChecker)
    },
    mouseup (e) {
      if (e.target.id === OVERLAY_ID && this.targetClick) {
        this.$modals.hideTopModal()
      }
      this.targetClick = false
    },
    mousedown (e) {
      this.targetClick = e.target.id === OVERLAY_ID
    },
    resizeHandler () {
      requestAnimationFrame(() => this.updateTrueScreenHeight())
    },
    calcScrollWidth () {
      if (this.isMobile) return 0
      return window.innerWidth - document.documentElement.clientWidth
    },
    updateScrollWidth () {
      document.documentElement.style.setProperty('--scroll-width', `${this.calcScrollWidth()}px`)
    },
    updateTrueScreenHeight () {
      document.documentElement.style.setProperty(
        '--true-screen-height',
        `${document.querySelector(`#${SCREEN_SIZE_CHECKER_ID}`).getBoundingClientRect().height}px`
      )
    },
    onKeyPress (e) {
      if (/[Ee]scape/.test(e.key)) {
        this.$modals.hideTopModal()
      }
    }
  }
}
</script>

<style lang="scss">
.modal-holder {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  &.active {
    z-index: 10;
  }
}

.__modal-root {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  background: #00000099;
}

.fade-enter-active {
  transition: opacity var(--bg-show-time);
}
.fade-leave-active {
  transition: opacity var(--bg-hide-time);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss">
.screen-size-checker {
  position: fixed;
  inset: 0;
  z-index: -30;
  background: transparent;
}
</style>
