import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state(),
  data: [
    { id: 'INSCRIT', title: 'INSCRIT' },
    { id: 'NON-INSCRIT', title: 'NON-INSCRIT' },
    { id: 'RADIÉ', title: 'RADIÉ' }
  ]
})
export const getters = {
  ...defaultFilter.getters
}
export const mutations = {
  ...defaultFilter.mutations
}
