<template>
  <label class="wrap" :class="inputClasses" :invalid="errorState">
    <span
      v-show="showPlaceholder"
      class="cinput-placeholder"
      :class="{'cinput-placeholder-focused': focus || localTarget}"
    >
      {{ placeholder }}
    </span>
    <input
      v-if="isInput"
      ref="input"
      v-model="localTarget"
      :type="format"
      class="cinput"
      :readonly="readonly"
      :disabled="disabled"
      @input="input"
      @focusin="focusin"
      @focusout="focusout"
      @keypress="$emit('keypress', $event)"
      @keydown="$emit('keydown', $event)"
    >
    <textarea
      v-else-if="isTextarea"
      ref="input"
      v-model="localTarget"
      class="cinput"
      :readonly="readonly"
      @input="input"
      @focusin="focusin"
      @focusout="focusout"
      @keypress="$emit('keypress', $event)"
    />
    <template v-if="iconType === 'search'">
      <CButton
        v-if="isPhone"
        class="button-icon"
        border-type="rounded"
        @click="$emit('search')"
      >
        <ChevronRight class="next-icon" />
      </CButton>
      <SearchIcon class="input-icon" :class="iconClasses" />
    </template>
    <span class="error-message" @transitionrun="onError">{{ errorMessage }}</span>
  </label>
</template>

<script>
import ChevronRight from 'assets/svg/chevron-right-alt.svg'
import SearchIcon from '../../assets/svg/search.svg'
import CButton from '@/components/common/CButton'
import deviceInfo from '~/mixins/deviceInfo'

const TEXTAREA_TYPE = 'textarea'
const INPUT_TYPE = 'input'

export default {
  name: 'CInput',
  components: {
    SearchIcon,
    ChevronRight,
    CButton
  },
  mixins: [deviceInfo],
  model: {
    prop: 'target',
    event: 'input'
  },
  props: {
    type: {
      default: 'input',
      validator (value) {
        return [INPUT_TYPE, TEXTAREA_TYPE].includes(value)
      }
    },
    target: {
      default: null
    },
    size: {
      default: 'small',
      validator (value) {
        return ['small', 'medium'].includes(value)
      }
    },
    borderType: {
      default: 'easy-rounded',
      validator (value) {
        return ['easy-rounded', 'rounded'].includes(value)
      }
    },
    iconSize: {
      default: 'small',
      validator (value) {
        return ['small', 'medium', null].includes(value)
      }
    },
    searchIconColor: {
      default: 'default',
      validator (value) {
        return ['default', 'gray'].includes(value)
      }
    },
    iconType: {
      default: null,
      validator (value) {
        return ['search', null].includes(value)
      }
    },
    iconPosition: {
      default: 'left-sm',
      validator (value) {
        return ['right-sm', 'right-md', 'left-sm', 'left-md', null].includes(value)
      }
    },
    placeholder: {
      default: ''
    },
    flexPlaceholder: {
      default: false
    },
    disabled: {
      default: false
    },
    format: {
      default: 'text',
      validator (value) {
        return ['text', 'password'].includes(value)
      }
    },
    readonly: {
      default: false
    },
    errorState: {
      default: false
    },
    errorMessage: {
      default: 'Error'
    }
  },
  data () {
    return {
      localTarget: '',
      paddingClasses: {
        'right-sm': 'p-right-sm',
        'right-md': 'p-right-md',
        'left-sm': 'p-left-sm',
        'left-md': 'p-left-md'
      },
      focus: false,
      showPlaceholder: true
    }
  },
  computed: {
    isInput () {
      return this.type === INPUT_TYPE
    },
    isTextarea () {
      return this.type === TEXTAREA_TYPE
    },
    iconClasses () {
      const classes = []
      if (this.iconSize !== null) classes.push(this.iconSize)
      if (this.iconPosition !== null) classes.push(this.iconPosition)
      if (this.searchIconColor === 'gray') classes.push('gray')
      return classes
    },
    inputClasses () {
      const classes = [this.borderType, `wrap-${this.size}`]
      if (this.iconPosition !== null && this.iconType) classes.push(this.paddingClasses[this.iconPosition])
      if (this.focus) {
        classes.push('focused')
      }

      if (this.flexPlaceholder) classes.push('wrap__flex-placeholder')
      if (this.disabled) classes.push('wrap_disabled')

      return classes
    }
  },
  watch: {
    localTarget () {
      if (!this.flexPlaceholder) this.showPlaceholder = !this.localTarget
    },
    target () {
      this.localTarget = this.target
    },
    focus (nv) {
      if (!this.flexPlaceholder) {
        if (nv) {
          this.showPlaceholder = false
        } else if (!this.localTarget || !this.localTarget.length) {
          this.showPlaceholder = true
        }
      }
    }
  },
  created () {
    this.localTarget = this.target
  },
  methods: {
    input (e) {
      if (e && e.inputType === 'insertCompositionText') {
        this.localTarget = e.target.value
      }
      this.$emit('input', this.localTarget)
    },
    focusin (e) {
      this.focus = true
      this.$emit('focusin', e)
    },
    focusout (e) {
      this.focus = false
      this.$emit('focusout', e)
    },
    onError (e) {
      e.target.parentElement.style.setProperty(
        '--error-height',
        `${e.target.scrollHeight}px`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  display: block;
  background: var(--input-background);
  cursor: text;
  transition: all var(--transition-time) ease;
  --transition-time: 0.25s;
  border: 1px solid transparent;
  height: min-content;

  &[invalid],
  &[error] {
    border-color: var(--error-state-color);
    margin-bottom: calc(var(--error-height) + 4px);

    .error-message {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .error-message {
    display: inline-block;
    color: var(--error-state-color);
    transform: translateY(-100%);
    left: 0;
    top: 100%;
    width: 100%;
    opacity: 0;
    transition: all var(--transition-time) ease;
    position: absolute;
    margin-top: 4px;
  }

  &_disabled {
    cursor: default;

    & .cinput {
      color: rgba(30, 34, 39, 0.6) !important;
    }
  }

  &-small {
    padding: 9px 16px;
    @include very-small-font;
  }

  &-medium {
    padding: 19px 16px;
    @include normal-font;

    & .cinput {
      color: var(--title);
    }
  }

  &__flex-placeholder {
    padding-top: 29px;
    padding-bottom: 9px;

    & .cinput-placeholder {
      top: var(--placeholder-top, 20px);
      font-size: var(--placeholder-font-size, inherit);
      font-weight: var(--placeholder-font-weight, inherit);
      font-family: var(--placeholder-font-family, inherit);
    }

    & .cinput-placeholder-focused {
      font-weight: var(--placeholder-font-weight-focused, 600);
      top: var(--placeholder-top-focused, 10px);
      font-size: var(--placeholder-font-size-focused, 12px);
      font-family: var(--placeholder-font-family-focused, inherit);
    }
  }
  .button-icon {
    display: none;
    @include centrifyY;
    --svg-color: var(--white);
    padding: 14px 15px;

    &:hover svg,
    &:focus svg {
      animation: little-jump 2s ease 0s infinite normal forwards;
      --base-offset-x: 0px;
      --max-offset-x: 5px;
    }

    .next-icon {
      width: 6px;
      height: 10px;
      margin-left: 2px;
    }
  }
}

.cinput {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  background-color: inherit;

  &::placeholder {
    opacity: 1;
  }

  &-placeholder {
    user-select: none;
    color: var(--input-placeholder);
    position: absolute;
    transition: all 0.25s ease;
  }
}

.input-icon {
  @include centrifyY;
  right: 0;
  --svg-color: var(--title);
}

.easy-rounded {
  border-radius: 5px;
}

.rounded {
  border-radius: 50px;
}

.small {
  width: 16px;
  height: 16px;
}

.medium {
  width: 24px;
  height: 24px;
}

.big {
  width: 32px;
  height: 32px;
}

.right-sm {
  right: 16px;
}

.right-md {
  right: 24px;
}

.left-sm {
  left: 16px;
}

.left-md {
  left: 24px;
}

.p-right-sm {
  padding-right: 40px;
}

.p-right-md {
  padding-right: 60px;
}

.p-left-sm {
  padding-left: 40px;
}

.p-left-md {
  padding-left: 60px;
}

.gray {
  --svg-color: var(--light-gray);
}

@include phone {
  .wrap {
    .cinput-placeholder {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .input-icon {
      display: none;
    }

    .button-icon {
      display: flex;
      right: 6px;
    }
  }
}
</style>
