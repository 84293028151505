/* eslint-disable max-classes-per-file */
export class Vector2 {
  constructor(x = 0, y = 0) {
    this.x = x; this.y = y
  }

  static dot(vec1, vec2) {
    return vec1.x * vec2.x + vec1.y * vec2.y
  }

  static add(vec1, vec2) {
    return new Vector2(vec1.x + vec2.x, vec1.y + vec2.y)
  }

  static subtract(vec1, vec2) {
    return new Vector2(vec1.x - vec2.x, vec1.y - vec2.y)
  }

  static multiply(vec, factor) {
    return new Vector2(vec.x * factor, vec.y * factor)
  }

  static divide(vec, divider) {
    return new Vector2(vec.x / divider, vec.y / divider)
  }

  inverted() {
    return new Vector2(-this.x, -this.y)
  }

  invert() {
    this.x = -this.x
    this.y = -this.y
    return this
  }

  normalize() {
    const length = this.length()
    this.x /= length
    this.y /= length
    return this
  }

  normalized() {
    const length = this.length()

    return new Vector2(
      this.x / length,
      this.y / length
    )
  }

  length() {
    return Math.sqrt(this.x ** 2 + this.y ** 2)
  }

  static project(which, where) {
    return (which.x * where.x + which.y * where.y) / Math.sqrt(where.x ** 2 + where.y ** 2)
  }
}

export class Utils {
  static multiplyPoint(factor, point) {
    return { x: point.x * factor, y: point.y * factor }
  }

  static dist(pointOne, pointTwo) {
    return Math.sqrt((pointTwo.x - pointOne.x) * (pointTwo.x - pointOne.x) + (pointTwo.y - pointOne.y) * (pointTwo.y - pointOne.y))
  }

  static radsToDegs(rads) {
    return rads * 180 / Math.PI
  }

  static degsToRads(degs) {
    return degs * Math.PI / 180
  }

  static pointByAngle(rads, radius, center) {
    return {
      x: radius * Math.cos(rads) + (center ? center.x : 0),
      y: radius * Math.sin(rads) + (center ? center.y : 0)
    }
  }
}

export class Primitives {
  static RegularByCircleRadius(angleCount, radius, options) {
    if (angleCount >= 3) {
      const baseopt = { startAngle: -Math.PI, center: null }
      const opts = Object.assign(baseopt, options)
      const oneSectorRads = Math.PI * 2 / angleCount
      const result = []

      for (let i = 0; i < angleCount; i++) {
        result.push(Utils.pointByAngle(opts.startAngle + oneSectorRads * i, radius, opts.center))
      }
      return result
    }
    throw new Error('angleCount must be a number more than 2')
  }
}
