export async function setLoginGTM (data, isActiveScriptAnalytics, scripts) {
  try {
    if (isActiveScriptAnalytics) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        userId: data.id,
        reference: data.reference,
        event: 'login'
      })

      await setGTM(isActiveScriptAnalytics, scripts)
    }
  } catch (e) {
    console.error(e)
  }
}

export function gtag () {
  if (process.server) return

  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(arguments)
}

export async function setGTM (isActiveScriptAnalytics, scripts) {
  function insertScript (src, isAsync, body) {
    const firstScript = document.head.getElementsByTagName('script')[0]
    const script = document.createElement('script')
    script.async = isAsync
    script.src = src
    script.body = body
    firstScript.parentNode.insertBefore(script, firstScript)
  }

  if (isActiveScriptAnalytics) {
    if (scripts && scripts[0]?.ads_script) {
      const adsScripts = scripts.map(item => item.ads_script)
      const adsIds = adsScripts.filter(item => item.includes('GTM'))

      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      if (adsIds.length) {
        adsIds.forEach(item => {
          if (item.includes('GTM')) insertScript(`https://www.googletagmanager.com/gtm/js?id=${item}`, true, false)
        })
      }
      const notInAdsId = adsScripts.find(item => !adsIds.includes(item))
      if (notInAdsId) insertScript(`https://www.googletagmanager.com/gtag/js?id=${notInAdsId}`, true, false)
    }
  }
}
