<template>
  <div class="header-search-wrapper">
    <CInput
      ref="input"
      v-model="searchString"
      :icon-type="iconType[variation]"
      :icon-position="iconPosition[variation]"
      :placeholder="placeholders[variation]"
      class="header-search-bar custom-cinput"
      :class="variation"
      @input="search"
      @focusin="isActive = true"
      @focusout="focusOut"
      @keydown="keypress"
      @search="goSearchPage(searchString)"
    />
    <div class="header-help-bar" :class="helpClasses">
      <div
        v-for="(suggest, i) in suggests"
        :key="i"
        class="header-help-bar__value"
        :class="{'header-help-bar__value_active': i === selectIndex}"
        @click="clickSuggest(i)"
      >
        <span v-html="highlighting(ucFirst(suggest), extraSearchString)" />
        <NextIcon />
      </div>
      <div class="info">
        <hr>
        <span>Les infobulles ne prennent pas en compte les filtres</span>
      </div>
    </div>
  </div>
</template>

<script>
import CInput from '@/components/common/CInput.vue'
import NextIcon from '@/assets/svg/right-arrow-simple.svg'
import suggest from '~/mixins/suggest'
import deviceInfo from '~/mixins/deviceInfo'

export default {
  name: 'HeaderSearchBar',
  components: {
    CInput, NextIcon
  },
  mixins: [suggest, deviceInfo],
  props: {
    variation: {
      default: 'main',
      validator (value) {
        return ['main', 'mobile', 'mobile-menu'].includes(value)
      }
    }
  },
  data () {
    return {
      placeholders: {
        main: 'Dénomination sociale, siret, siren...',
        mobile: 'Search...',
        'mobile-menu': 'Dénomination sociale, siret, siren...'
      },
      iconType: {
        main: 'search',
        mobile: null,
        'mobile-menu': 'search'
      },
      iconPosition: {
        main: 'right-md',
        mobile: null,
        'mobile-menu': 'right-sm'
      },
      searchString: '',
      extraSearchString: '',
      loadId: null,
      isActive: false,
      selectIndex: -1,
      suggests: []
    }
  },
  computed: {
    helpClasses () {
      const classes = { 'header-help-bar_active': this.isActive && this.suggests.length }
      classes[this.variation] = true

      return classes
    }
  },
  watch: {
    searchString () {
      if (this.selectIndex === -1) this.extraSearchString = this.searchString
    }
  },
  methods: {
    search () {
      if (this.loadId) clearTimeout(this.loadId)
      this.loadId = setTimeout(async () => {
        this.suggests = await this.$api.search.suggest({ q: this.searchString, limit: 3 })
      }, 1000)
    },
    instantNavigation () {
      this.$api.search.combinedSearch({
        limit: 1,
        offset: 0,
        q: this.suggests[0]
      })
        .then(res => {
          this.focusOut()
          this.$router.push(`/entreprise/${this.suggests[0]}-${res.items[0].siren}`)
        })
    },
    keypress (e) {
      if (e.key.toLowerCase() === 'enter') {
        if (this.suggests.length === 1) {
          this.instantNavigation()
        } else {
          this.goSearchPage(this.searchString)
        }
      } else if (e.key.toLowerCase() === 'arrowdown') {
        e.preventDefault()
        this.chooseSuggest(1)
      } else if (e.key.toLowerCase() === 'arrowup') {
        e.preventDefault()
        this.chooseSuggest(-1)
      }
    },
    clickSuggest (e) {
      if (this.suggests.length === 1) {
        this.instantNavigation()
      } else {
        this.goSearchPage(this.suggests[e])
      }
    },
    chooseSuggest (i) {
      if (this.selectIndex <= 0 && i === -1) {
        this.selectIndex = this.suggests.length - 1
      } else if (this.selectIndex >= this.suggests.length - 1 && i === 1) {
        this.selectIndex = 0
      } else {
        this.selectIndex += i
      }

      this.searchString = this.suggests[this.selectIndex]
    },
    goSearchPage (suggest) {
      if (this.loadId) clearTimeout(this.loadId)
      if (this.isMobile) {
        this.$modals.hideModal(this.$modals.names.MobileMenuModal)
      }
      this.searchString = ''
      this.isActive = false
      this.suggests = []
      this.selectIndex = -1
      if (suggest && suggest.length) {
        if (this.$refs.input?.$refs?.input) this.$refs.input.$refs.input.blur()
        this.$router.push({
          path: '/recherche',
          query: { q: suggest }
        })
      } else {
        this.$router.push({ path: '/recherche' })
      }
    },
    focusOut () {
      setTimeout(() => { this.isActive = false }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  display: none;
}

.header-search-wrapper {
  position: relative;
  width: 100%;
}

.header-help-bar {
  position: absolute;
  top: 42px;
  z-index: 2;
  background-color: var(--input-background);
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--title);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 6px 13px rgba(0, 0, 0, 0.05);

  animation-duration: 0.25s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-name: rolling-vertical-out, disappearing;
  transform-origin: top;

  &_active {
    animation-name: rolling-vertical-in, appearing;
  }

  &__value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    --svg-color: black;
    user-select: none;
    cursor: pointer;

    & span {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover,
    &_active {
      background-color: #e8e8e8;
    }
  }
}

.header-help-bar_active {
  padding: 10px 0px 20px 0px;

  .info {
    display: block;
    padding: 10px 20px 0px 20px;
    color: var(--light-gray);
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;

    hr {
      margin: 0;
      margin-bottom: 18px;
      background: var(--separator);
    }
  }
}

.header-search-bar.custom-cinput {
  height: 100%;
  max-height: 34px;
  width: 100%;
  @include flex-line-start;

  &.mobile {
    max-width: 100%;
    padding: 9px 7px;
    background: transparent;
    --input-background: transparent;
    --input-placeholder: var(--paragraph);

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      height: 1px;
    }

    &::before {
      left: 0;
      right: 0;
      background: var(--separator);
    }

    &::after {
      left: 50%;
      transform: translate(-50%);
      width: 0;
      background: var(--pressed-brand);
      transition: all 0.25s ease-in-out;
    }

    &.focused {
      &::after {
        width: 100%;
      }
    }
  }

  &.mobile-menu {
    max-height: 52px;
  }
}

@keyframes rolling-vertical-in {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes rolling-vertical-out {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

@include tablet-landscape {
  .header-search-wrapper {
    .mobile-menu.header-help-bar {
      top: 60px;
    }
  }
}
</style>

<style lang="scss">
.header-help-bar__value {
  & mark {
    color: var(--brand);
    text-decoration: unset !important;
  }
}
</style>
