import Api from './Api'

class Meta extends Api {
  constructor (_axios) {
    super(_axios, '/metas')
  }

  showByAlias (alias, merchantDomain) {
    return this.get(`/alias/${alias}`, {
      merchant_domain: merchantDomain
    })
  }

  loadInformation () {
    return this.get('/information')
  }
}

export default Meta
