import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state(),
  data: [
    {
      title: 'Actif',
      id: '4'
    }, {
      title: 'Fermé',
      id: '3'
    }, {
      title: 'Une procédure collective est en cours',
      id: '1'
    }, {
      title: 'Société principale fermée',
      id: '0'
    }
  ]

})
export const getters = {
  ...defaultFilter.getters
}
export const mutations = {
  ...defaultFilter.mutations

}
