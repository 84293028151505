// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow-button{margin-top:30px;--svg-color:currentColor}.arrow-button .arrow{margin-left:6px}.arrow-animated:focus svg,.arrow-animated:hover svg,.arrow-button:focus svg,.arrow-button:hover svg{-webkit-animation:little-jump 2s ease 0s infinite normal forwards;animation:little-jump 2s ease 0s infinite normal forwards;--base-offset-x:0px;--max-offset-x:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet-landscape": "1160px",
	"tablet-portrait": "900px",
	"phone": "640px"
};
module.exports = ___CSS_LOADER_EXPORT___;
