<template>
  <div class="search-input-suggest-wrapper" :class="classes" @animationend="animationend">
    <SuggestMain
      v-if="type === 'main'"
      class="main"
      :q="q"
      :active="active"
      :on-link-click="onLinkClick"
      :params="params"
      :items="items"
      :select-index="selectIndex"
      @click="(i) => $emit('click', i)"
    />
    <SuggestServices
      v-else
      :active="active"
      :on-link-click="onLinkClick"
      :create-link-functor="createLinkFunctor"
      :params="params"
      :items="items"
    />
  </div>
</template>

<script>
import SuggestMain from './variations/SuggestMain'
import SuggestServices from '~/components/forms/SearchInputSuggest/variations/SuggestServices'

export default {
  components: {
    SuggestServices,
    SuggestMain
  },
  props: {
    type: {
      default: 'main'
    },
    createLinkFunctor: {
      default: () => item => `/entreprise/${item.url}`
    },
    onLinkClick: {
      default: undefined
    },
    active: {
      default: false
    },
    params: {
      default: null
    },
    items: {
      default: () => []
    },
    q: {
      default: ''
    },
    selectIndex: {
      default: -1
    }
  },
  data () {
    return {
      hidden: true
    }
  },
  computed: {
    classes () {
      const classes = {
        active: this.active,
        hidden: this.hidden
      }

      classes[this.type] = true

      return classes
    }
  },
  methods: {
    animationend (e) {
      if (!this.active) {
        this.hidden = true
      } else {
        this.hidden = false
      }
    }
  }
}
</script>

<style lang="scss">
.search-input-suggest-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  & .main {
    margin-top: 72px;
  }

  &.hidden {
    display: none;
  }

  &.active,
  &.hidden.active {
    display: block;
  }
}

@include tablet-landscape {
  .search-input-suggest-wrapper {
    &.error-page {
      z-index: 4;

      & .main {
        margin-top: 56px;
      }
    }
  }
}
</style>
