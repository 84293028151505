export default {
  clamp(value, min, max) {
    return value > max ? max : (value < min ? min : value)
  },
  validationClamp(value, min, max) {
    return +value > +max ? false : (!(+value < +min))
  },
  ceilToEven(value) {
    return Math.round((value + 2) / 2) * 2
  },
  roundToEven(value) {
    return value % 2 >= 1 ? this.ceilToEven(value) : this.floorToEven(value)
  },
  floorToEven(value) {
    return Math.round(value / 2) * 2
  },

  getRandomInt(min, max) {
    const _min = Math.ceil(min)
    const _max = Math.floor(max)
    return Math.floor(Math.random() * (_max - _min)) + _min // Максимум не включается, минимум включается
  },
  getRandom(min, max, fix = null) {
    const res = Math.random() * (max - min) + min
    return fix === null ? res : +res.toFixed(fix)
  }
}
