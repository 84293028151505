import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state(),
  data: [
    {
      title: 'Petite ou Moyenne Entreprise',
      id: 'PME'
    }, {
      title: 'Entreprise de Taille Intermédiaire',
      id: 'ETI'
    }, {
      title: 'Grande Entreprise',
      id: 'GE'
    }
  ]

})
export const getters = {
  ...defaultFilter.getters
}
export const mutations = {
  ...defaultFilter.mutations

}
