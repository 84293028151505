/* eslint-disable */
import ResponseError from '~/services/responseError'

let _axios = null

export function setToken(token = '', type = '') {
  _axios.setToken(token, type)
}

const EXTRA_PATH = [
  '/auth/password'
]

export default async function ({
  $axios,
  store,
  error
}) {
  _axios = $axios

  $axios.onError(async e => {
    switch (e.response.status) {
      case 401: {
        if (!EXTRA_PATH.includes(e.response.config.url)) store.dispatch('auth/signOut')
        break
      }
      case 404: {
        if(e.response.config.url.includes('site-clones/')){
          return Promise.reject('404')
        }
        error({
          statusCode: 404,
          message: 'Not found'
        })
        const notFoundError = new ResponseError('404')
        return Promise.resolve({ data: notFoundError });
      }
      case 429: {
        if (process.server) {
          error({
            statusCode: 429,
            message: 'Il y a trop de demandes. Veuillez réessayer plus tard.'
          })
          return Promise.resolve(false);
        } else if (process.client) {
          store.dispatch('snackbar/addNotifications', {
            title: 'Erreur',
            content: 'Il y a trop de demandes. Veuillez réessayer plus tard.',
            type: 'error',
            timeToDie: 5000
          })
          window.onNuxtReady(() => {
            window.$nuxt.error({
              statusCode: 429
            })
          })
        }

        break
      }
      case 500: {
        if (process.client) {
          store.commit('snackbar/addNotifications', {
            title: 'Erreur',
            content: `Quelque chose ne va pas (${e.response.status})`,
            type: 'error',
            timeToDie: 5000
          })
          window.onNuxtReady(() => {
            window.$nuxt.error({
              statusCode: e.response.status
            })
          })
        }
      }
    }
    return Promise.reject(e)
  })
}
