export default {
  MobileMenuModal: 'MobileMenuModal',
  LoginModal: 'LoginModal',
  RegistrationModal: 'RegistrationModal',
  PasswordRecoveryModal: 'PasswordRecoveryModal',
  PremiumModal: 'PremiumModal',
  IntelligentsFiltersModal: 'IntelligentsFiltersModal',
  BaseFiltersModal: 'BaseFiltersModal',
  FinancesFiltersModal: 'FinancesFiltersModal',
  PersonsFiltersModal: 'PersonsFiltersModal',
  AllFiltersModal: 'AllFiltersModal',
  RejectFormModal: 'RejectFormModal',
  MobileFiltersModal: 'MobileFiltersModal'
}
