<template>
  <header class="mobile-header" :class="{'over-all': menuOpened}">
    <div class="header">
      <div class="wrapper">
        <BrandLogo
          :class="{hidden:isPhone && versions['search-bar'].activeIf() || !versions['main'].activeIf()}"
          class="header-logo"
          @click.native="$modals.hideModal($modals.names.MobileMenuModal)"
        />
        <HeaderSearchBar
          class="search-bar"
          :variation="isPhone?'mobile':'mobile-menu'"
          :class="{hidden:!versions['search-bar'].activeIf()}"
        />
        <BurgerMenu
          class="burger"
          :value="menuOpened"
          aria-label="Open mobile menu"
          @input="onClick"
        />
      </div>
    </div>
    <client-only>
      <MobileMenuModal />
    </client-only>
  </header>
</template>

<script>
import BrandLogo from './parts/BrandLogo.vue'
import BurgerMenu from './parts/BurgerMenu.vue'
import HeaderSearchBar from './parts/HeaderSearchBar.vue'
import MobileMenuModal from '~/components/modals/MobileMenuModal'
import { MOBILE_MENU_ANIMATION_TIME } from '@/components/modals/MobileMenuModal'
import deviceInfo from '~/mixins/deviceInfo'

export default {
  name: 'MainHeaderMobile',
  components: {
    BurgerMenu, BrandLogo, HeaderSearchBar, MobileMenuModal
  },
  mixins: [deviceInfo],
  data () {
    return {
      currentVersion: 'search-bar',
      menuOpened: false,
      versions: {
        'search-bar': {
          activeIf: () => this.$route.path.startsWith('/entreprise') && !this.menuOpened || this.isMobile && !this.isPhone

        },
        main: {
          activeIf: () => true
        }
      }
    }
  },
  computed: {
    isMobileMenuActive () {
      return process.client && this.$modals.openedModals.includes(this.$modals.names.MobileMenuModal)
    }
  },
  watch: {
    isMobileMenuActive (v) {
      if (!v) {
        this.__timeout = setTimeout(() => {
          this.menuOpened = false
        }, MOBILE_MENU_ANIMATION_TIME + 50)
      } else {
        this.menuOpened = true
        clearTimeout(this.__timeout)
      }
    }
  },
  methods: {
    onClick (v) {
      if (!v) {
        this.$modals.hideModal(this.$modals.names.MobileMenuModal)
      } else {
        this.$modals.showModal(this.$modals.names.MobileMenuModal)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.mobile-header {
  height: 100vh;
  max-height: 56px;
  position: relative;
  max-width: 100vw;

  &.over-all .header {
    z-index: 11;
    position: fixed;
    left: 0;
    top: 0;
  }

  .header {
    z-index: 2;
    height: 100%;
    position: relative;
    max-height: inherit;
    background: var(--white);
    width: 100%;
    border-bottom: 1px solid var(--separator);

    .wrapper {
      height: 100%;
      position: relative;
      @include flex-line-between;
      gap: 53px;
      margin: 0 auto;

      .burger {
        right: 0;
        max-width: 60px;
        max-height: 100%;
      }

      .header-logo {
        opacity: 1;
        transition: all 0.25s ease;
        position: relative;
        left: 0;
        margin-left: 16px;

        &.hidden {
          opacity: 0;
          pointer-events: none;
        }
      }

      .search-bar {
        width: 327px;
        font-size: var(--font-size-normal-s1);
        line-height: var(--line-height-normal-l1);
        @include animation-appearing;
        &.hidden {
          display: none;
        }
      }

      @media (max-width: 640px) {
        gap: 16px;

        justify-content: flex-end;
        .header-logo {
          position: absolute;
          order: 1;
          left: calc(50% - 67px);
        }
      }
    }
  }
}
</style>
