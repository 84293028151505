import * as defaultFilter from '@/services/defaultFilter.js'

export const COMBINED_MODE = 'combined'
export const SEPARATED_MODE = 'separated'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters,
  mapper: state => value => null,
  getDefaultValue () {
    return COMBINED_MODE
  },
  isCombined (state, getters) {
    return getters.getValue === COMBINED_MODE
  },
  isSeparated (state, getters) {
    return getters.getValue === SEPARATED_MODE
  }
}
export const mutations = {
  ...defaultFilter.mutations
}
