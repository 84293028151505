/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr)
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr)
  }
}

if (!Array.prototype.last) {
  Object.defineProperty(Array.prototype, 'last', {
    value (index = 0) {
      return this[this.length - 1 - index]
    }
  })
}
if (!Array.prototype.cycledAt) {
  Object.defineProperty(Array.prototype, 'cycledAt', {
    value (index = 0) {
      if (index >= 0) return this[index % this.length]
      return this[index % this.length + this.length]
    }
  })
}
