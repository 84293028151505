import { gtag } from '~/services/analytics'
import { BENEFICIAIRES_TARGET, COMPANIES_TARGET, REPRESENTANTS_TARGET } from '~/store/filters/targets'
import { COMBINED_MODE } from '~/store/filters/searchMode'

export const state = () => ({
  searchResult: {
    count: 0,
    items: [],
    searchString: ''
  },
  targetToNumberMap: {
    enterprises: 0,
    representants: 1,
    beneficiaires: 2,
    documents: 3
  },
  searchType: {
    enterprises: true,
    representants: true,
    beneficiaires: true,
    documents: false
  },
  loading: false,
  searchError: false,
  searchCounts: {
    enterprises: null,
    representants: null,
    beneficiaires: null,
    documents: null
  },
  combinedLimit: 12,
  userUsedFilters: new Set()
})

export const getters = {
  searchType (state) {
    return state.searchType
  },
  searchCounts (state) {
    return state.searchCounts
  },
  targetToNumberMap (state) {
    return state.targetToNumberMap
  },
  searchError (state) {
    return state.searchError
  },
  searchResult (state) {
    return state.searchResult
  },
  loading (state) {
    return state.loading
  },
  separatedLimits (state, getters, _, rootGetters) {
    if (getters.typesCount === 1) {
      return {
        enterprises: 12,
        representants: 12,
        beneficiaires: 12,
        documents: 12
      }
    }
    return {
      enterprises: rootGetters['device/isMobile'] ? 12 : 3,
      representants: rootGetters['device/isMobile'] ? 12 : 3,
      beneficiaires: rootGetters['device/isMobile'] ? 12 : 3,
      documents: rootGetters['device/isMobile'] ? 12 : 2
    }
  },
  typesCount (state) {
    return Object.values(state.searchType)
      .filter(value => value).length
  }
}

export const actions = {
  async load ({
    dispatch,
    commit
  }, context) {
    await dispatch('filters/initContext', context, { root: true })
    await dispatch('filters/loadParams', context.route.query, { root: true })
    await dispatch('searchModeInit')
    await dispatch('targetsInit')
    await dispatch('searchTypeInit')
    commit('targetToNumberMapInit')

    await dispatch('search')
  },
  searchModeInit ({
    commit,
    rootGetters
  }) {
    if (!rootGetters['filters/searchMode/getValue']) {
      commit('filters/searchMode/setValue', COMBINED_MODE, { root: true })
    }
  },
  targetsInit ({
    commit,
    rootGetters
  }) {
    if (!rootGetters['filters/targets/getValue']) {
      commit('filters/targets/setValue', [COMPANIES_TARGET, REPRESENTANTS_TARGET, BENEFICIAIRES_TARGET], { root: true })
    }
  },
  searchTypeInit ({
    commit,
    rootGetters
  }) {
    const tmp = {
      enterprises: false,
      representants: false,
      beneficiaires: false,
      documents: false
    }

    for (const i of rootGetters['filters/targets/getValue']) {
      switch (i.toString()) {
        case '0':
          tmp.enterprises = true
          break
        case '1':
          tmp.representants = true
          break
        case '2':
          tmp.beneficiaires = true
          break
        case '3':
          tmp.documents = true
          break
      }
    }

    commit('searchType', tmp)
  },
  paginate ({
    getters,
    commit,
    dispatch
  }, {
    index,
    page
  }) {
    return dispatch('separatedSearch', {
      category: index,
      page
    })
      .then(res => {
        commit('searchResultKey', {
          index,
          items: getters.searchResult.items[index].items.concat(res.items),
          count: res.count,
          page,
          itemsPerPage: getters.separatedLimits[index]
        })

        return res
      })
  },
  async getParams ({
    getters,
    state,
    dispatch
  }, {
    category = null,
    page = 1
  }) {
    const result = await dispatch('filters/getPreparedValues', null, { root: true })

    result.limit = category === null ? state.combinedLimit : getters.separatedLimits[category]

    result.offset = (page - 1) * result.limit

    return result
  },
  async search ({
    dispatch,
    state,
    commit,
    rootGetters
  }) {
    if (state.userUsedFilters && state.userUsedFilters.size) {
      this.$api.services.sendFiltersStatistic([...state.userUsedFilters]).catch(() => undefined)
    }

    if (rootGetters['filters/searchMode/isCombined']) {
      const params = await dispatch('getParams', {})
      if (!Object.keys(params).includes('limit') || !Object.keys(params).includes('offset')) return null
      if (params.q && params.q.length < 3) return null

      await dispatch('beforeSearch')
      return this.$api.search.combinedSearch(params)
        .then(response => dispatch('actResultCombined', response))
        .catch(() => dispatch('onError'))
    }

    const response = {
      enterprises: null,
      representants: null,
      beneficiaires: null,
      documents: null
    }

    await dispatch('beforeSearch')
    const requests = []
    for (const i in state.searchType) {
      if (state.searchType[i]) {
        requests.push(
          dispatch('separatedSearch', { category: i })
            .then(x => {
              response[i] = x
            })
        )
      }
    }

    if (requests.length) {
      return Promise.all(requests)
        .then(() => dispatch('actResultSeparated', response))
    }
    commit('loading', false)
  },
  searchCategory ({
    dispatch,
    getters,
    commit
  }, {
    index,
    page
  }) {
    return dispatch('separatedSearch', {
      category: index,
      page
    })
      .then(res => commit('searchResultKey', {
        index,
        ...res,
        page,
        itemsPerPage: getters.separatedLimits[index]
      }))
  },
  async separatedSearch ({ dispatch }, {
    category,
    page = 1
  }) {
    const params = await dispatch('getParams', {
      category,
      page
    })
    if (!Object.keys(params).includes('limit') || !Object.keys(params).includes('offset')) return null
    if (params.q && params.q.length < 3) return null
    if (this.$config.production) gtag('event', 'establishment_search')

    switch (category) {
      case 'enterprises':
        return this.$api.legalUnites.search(params)
          .catch(() => dispatch('onError'))
      case 'representants':
        return this.$api.representants.search(params)
          .catch(() => dispatch('onError'))
      case 'beneficiaires':
        return this.$api.beneficiaires.search(params)
          .catch(() => dispatch('onError'))
      case 'documents':
        return this.$api.search.documents(params)
          .catch(() => dispatch('onError'))
      default:
        return null
    }
  },
  actResultSeparated ({
    getters,
    commit,
    rootGetters
  }, response) {
    const res = {
      count: 0,
      items: {},
      searchString: rootGetters['filters/q/getValue']
    }

    for (const i in response) {
      if (response[i] && response[i].count) {
        commit('searchCount', {
          type: i,
          value: response[i].count
        })
      } else {
        commit('searchCount', {
          type: i,
          value: null
        })
      }
      if (response[i] === null || response[i] === undefined) continue
      res.count += response[i].count
      res.items[i] = {
        ...response[i],
        itemsPerPage: getters.separatedLimits[i],
        page: 1
      }
    }

    commit('searchResult', res)
    commit('loading', false)
  },
  actResultCombined ({
    commit,
    state,
    rootGetters
  }, response) {
    if (this.$config.production) gtag('event', 'establishment_search')
    if (!Object.keys(response).length) return
    commit('searchResult', {
      ...response,
      searchString: rootGetters['filters/q/getValue']
    })

    if (response.count <= state.offset) {
      commit('paginateStop', true)
    }

    commit('loading', false)
  },
  onError ({ commit }) {
    commit('searchError', true)
    commit('loading', false)
  },
  beforeSearch ({
    commit
  }) {
    commit('searchError', false)
    commit('loading', true)
    commit('reset')
  }
}

export const mutations = {
  reset (state) {
    state.searchResult.count = 0
    for (const key in state.searchType) {
      state.searchCounts[key] = null
      delete state.searchResult.items[key]
    }
  },
  resetKey (state, key) {
    state.searchCounts[key] = null
    delete state.searchResult.items[key]
  },
  changeSearchType (state, {
    key,
    value
  }) {
    state.searchType[key] = value
  },
  searchType (state, value) {
    state.searchType = value
  },
  newItemsSearchResult (state, value) {
    state.searchResult.items = state.searchResult.items.concat(value)
  },
  searchResult (state, value) {
    state.searchResult = value
  },
  searchResultKey (state, {
    index,
    items,
    count,
    page,
    itemsPerPage
  }) {
    state.searchResult.items[index] = {
      items,
      count,
      page,
      itemsPerPage
    }
  },
  searchCount (state, {
    type,
    value
  }) {
    state.searchCounts[type] = value
  },
  loading (state, value) {
    state.loading = value
  },
  searchError (state, value) {
    state.searchError = value
  },
  paginateStop (state, value) {
    state.paginateStop = value
  },
  targetToNumberMapInit (state) {
    for (const i in state.targetToNumberMap) {
      state.targetToNumberMap[state.targetToNumberMap[i]] = i
    }
  },
  pushUserUsedFilter (state, filter) {
    state.userUsedFilters.add(filter)
  },
  dropUserUsedFilter (state, filter) {
    state.userUsedFilters.delete(filter)
  },
  dropAllUserUsedFilters (state) {
    state.userUsedFilters = new Set()
  }
}
