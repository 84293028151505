import * as defaultFilter from '@/services/defaultFilter.js'

export const COMPANIES_TARGET = 0
export const REPRESENTANTS_TARGET = 1
export const BENEFICIAIRES_TARGET = 2
export const DOCUMENTS_TARGET = 3

export const state = () => ({
  ...defaultFilter.state()
})

export const getters = {
  ...defaultFilter.getters,
  mapper: state => value => null
}

export const mutations = {
  ...defaultFilter.mutations
}
