<template>
  <button class="burger-button" :class="{active}" @click="onClick">
    <hr>
  </button>
</template>

<script>
export default {
  name: 'BurgerMenu',
  props: {
    value: {
      default: false
    }
  },
  data () {
    return {
      active: false
    }
  },
  watch: {
    value (n) {
      this.active = n
    }
  },
  created () {
    this.active = this.value
  },
  methods: {
    onClick () {
      this.$emit('input', this.active = !this.active)
    }
  }
}
</script>

<style lang="scss" scoped>
.burger-button {
  max-height: 24px;
  max-width: 24px;
  height: 100vh;
  width: 100vw;
  position: relative;
  background: transparent;
  @include flex-line-center;
  flex-direction: column;
  cursor: pointer;
  gap: var(--gap);
  --gap: 4px;
  --color: var(--brand);
  --height: 2px;
  --border-radius: 5px;
  --width: 20px;

  &:hover, &:focus {
    --color: var(--hovered-brand);
  }

  &:active {
    --color: var(--pressed-brand);
  }

  hr, hr::before, hr::after {
    margin: 0;
    padding: 0;
    border: none;
    transform: none;
    transform-origin: center;
    transition: all 0.25s ease-in-out;
  }

  hr {
    height: var(--height);
    width: var(--width);
    border-radius: var(--border-radius);
    background: var(--color);
    overflow: visible;
  }

  hr::before, hr::after {
    height: var(--height);
    width: var(--width);
    border-radius: var(--border-radius);
    background: var(--color);
    content: '';
  }

  hr::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(calc(0px - var(--gap) - var(--height)));
  }

  hr::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(calc(var(--gap) + var(--height)));
  }

  &.active {
    hr {
      transform: rotateZ(45deg);
    }

    hr::before, hr::after {
      transform: translateX(-50%) translateY(0) rotateZ(90deg);
    }

    hr::before {
      bottom: 0;
    }

    hr::after {
      top: 0;
    }
  }
}
</style>
