import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default ({ env, store }) => {
  if (!store.getters['device/isBot']) {
    Vue.use(VueReCaptcha, {
      siteKey: env.recaptchaToken,
      loaderOptions: {
        autoHideBadge: true
      }
    })
  }
}
