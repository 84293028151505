export const WRITE_TO_QUERY_DELAY = 300
export const state = () => ({
  default: null,
  value: null,
  data: null
})

export const getters = {
  get (state) {
    return state
  },
  getValue (state) {
    return state.value
  },
  getData (state) {
    return state.data
  },
  decoder: state => query => query,
  encoder: state => value => value,
  mapper: state => value => value,
  validator: state => value => true
}

export const mutations = {
  setValue (state, value) {
    state.value = value
    clearTimeout(this.____updateTimeout)
    this.____updateTimeout = setTimeout(x => {
      this.dispatch('filters/writeToQuery')
    }, WRITE_TO_QUERY_DELAY)
  },
  setData (state, data) {
    state.data = data
  }
}
