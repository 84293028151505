export default {
  head () {
    return this.getAnalytics()
  },
  computed: {
    isBot () {
      return this.$store.getters['device/isBot']
    }
  },
  methods: {
    getAnalytics () {
      const data = {
        link: [
          { rel: 'canonical', href: this.$config.appUrl + this.$route.path }
        ]
      }

      if (!this.isBot) {

      }

      return data
    }
  }
}
