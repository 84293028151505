import Service from '~/services/searchFiltersService'

export const state = () => ({
  ignoreNextQueryUpdate: false,
  ignoreNextQueryUpdateTimeout: null,
  justDropped: false
})

function filterChildGetters (allgetters, filter) {
  const gets = {}
  for (const i in allgetters) {
    const index = i.indexOf(`/${filter}`)
    if (index > -1) gets[i.substring(0, index)] = allgetters[i]
  }
  return gets
}

let __context = null

const finance = ['capital', 'closingDate', 'turnover',
  'netIncome', 'creditRate', 'returnOnEquity', 'rapidFilters']
const base = ['siren', 'placement', 'formJuridique', 'category',
  'statusRCS', 'status', 'APECode', 'employees', 'creationDate',
  'collectiveProcedures', 'emplacement', 'address']
const persons = ['dirigeantAge', 'dirigeantStatus', 'beneficiaireAge',
  'beneficiaireStatus', 'beneficiairePowers', 'beneficiaireId', 'representantId']
const intelligents = ['priorities', 'precision']

const beneficiaires = ['beneficiaireAge', 'beneficiaireStatus', 'beneficiairePowers']

const dirigeants = ['dirigeantAge', 'dirigeantStatus']

export const getters = {
  get (state) {
    return state
  },
  getNames (state) {
    const names = []
    for (const i in state) {
      names.push(i)
    }
    return names
  },
  getValues (state, getters) {
    return filterChildGetters(getters, 'getValue')
  },
  getDatas (state, getters) {
    return filterChildGetters(getters, 'getData')
  },
  getDecoders (state, getters) {
    return filterChildGetters(getters, 'decoder')
  },
  getEncoders (state, getters) {
    return filterChildGetters(getters, 'encoder')
  },
  getMappers (state, getters) {
    return filterChildGetters(getters, 'mapper')
  },
  getValidators (state, getters) {
    return filterChildGetters(getters, 'validator')
  },
  anyOfFinances (state, getters) {
    for (const i of finance) {
      if (getters[`${i}/getValue`] !== null) return true
    }
    return false
  },
  anyOfPersons (state, getters) {
    for (const i of persons) {
      if (getters[`${i}/getValue`] !== null) return true
    }
    return false
  },
  anyOfBase (state, getters) {
    for (const i of base) {
      if (getters[`${i}/getValue`] !== null) return true
    }
    return false
  },
  anyOfIntelligents (state, getters) {
    for (const i of intelligents) {
      if (getters[`${i}/getValue`] !== null) return true
    }
    return false
  },
  anyOfBeneficiaires (state, getters) {
    for (const i of beneficiaires) {
      if (getters[`${i}/getValue`] !== null) return true
    }
    return false
  },
  anyOfRepresentants (state, getters) {
    for (const i of dirigeants) {
      if (getters[`${i}/getValue`] !== null) return true
    }
    return false
  },
  any (state, getters) {
    for (const i of [...finance, ...persons, ...base, ...intelligents]) {
      if (getters[`${i}/getValue`] !== null) return true
    }
    return false
  },
  onlyIntelligents (state, getters) {
    return getters.anyOfIntelligents && !getters.anyOfBase && !getters.anyOfPersons && !getters.anyOfFinances
  },
  ignoreNextQueryUpdate (state) {
    return state.ignoreNextQueryUpdate
  },
  ignoreNextQueryUpdateTimeout (state) {
    return state.ignoreNextQueryUpdateTimeout
  },
  justDropped (state) {
    return state.justDropped
  }
}

export const mutations = {
  setIgnoreNextQueryUpdate (state, value) {
    state.ignoreNextQueryUpdate = value
  },
  setIgnoreNextQueryUpdateTimeout (state, value) {
    state.ignoreNextQueryUpdateTimeout = value
  },
  setJustDropped (state, value) {
    state.justDropped = value
  }
}

const saveNames = ['collectiveProcedures', 'APECode', 'formJuridique', 'dirigeantStatus']

export const actions = {
  async dropQuery ({ commit }) {
    commit('filters/q/setValue', null, { root: true })
  },
  async dropAll ({
    dispatch,
    commit,
    getters
  }) {
    await dispatch('drop', [...finance, ...persons, ...base, ...intelligents])
  },
  initContext ({}, context) {
    __context = context
  },
  dropFinances ({ dispatch }) {
    dispatch('drop', finance)
  },
  dropPersons ({ dispatch }) {
    dispatch('drop', persons)
  },
  dropBase ({ dispatch }) {
    dispatch('drop', base)
  },
  dropIntelligents ({ dispatch }) {
    dispatch('drop', intelligents)
  },
  dropRepresentants ({ dispatch }) {
    dispatch('drop', dirigeants)
  },
  dropBeneficiaires ({ dispatch }) {
    dispatch('drop', beneficiaires)
  },
  setData ({ commit }, value) {
    for (const i in value) {
      if (i === 'dirigeantStatus') {
        // eslint-disable-next-line no-param-reassign
        value[i][0].title = 'On ignore'
      }
      commit(`${i}/setData`, value[i])
    }
  },
  drop ({
    dispatch,
    state,
    commit
  }, names) {
    for (const i of names) {
      commit(`${i}/setValue`, null)
      commit('recherche/dropUserUsedFilter', i, { root: true })
    }
    commit('setJustDropped', true)
  },
  getPreparedValues ({
    getters
  }) {
    const mappers = getters.getMappers
    const res = {}
    const values = getters.getValues

    for (const i in values) {
      if (values[i] === undefined || values[i] === '' || values[i] === null) continue
      const temp = mappers[i](values[i])
      if (temp) {
        if (typeof temp === 'object' && temp.expand) {
          for (const name in temp) {
            res[Service.aliasBackend(name)] = temp[name]
          }
        } else {
          res[Service.aliasBackend(i)] = temp
        }
      }
    }

    return res
  },

  async loadParams ({
    dispatch,
    commit,
    getters
  }, query) {
    if (getters.ignoreNextQueryUpdate) {
      commit('setIgnoreNextQueryUpdate', false)
      return
    }
    await dispatch('dropAll')
    await dispatch('dropQuery')

    const decoded = Service.decoders.TurnInObject(query)
    const decoders = getters.getDecoders
    const validators = getters.getValidators
    for (const i in decoded) {
      let temp
      try {
        temp = decoders[i](decoded[i])
        if (!validators[i](temp)) continue
      } catch (e) {
        continue
      }

      commit(`${i}/setValue`, temp)
    }
  },

  loadFilters ({
    getters,
    dispatch
  }) {
    let needRequest = true
    if (!process.client) return
    if (window.localStorage) {
      const lastSynchro = localStorage.filtersLastSynchro
      if (lastSynchro !== undefined) {
        const currentDay = Math.floor(Date.now() / 1000 / 60 / 60 / 24)
        if (+lastSynchro === currentDay) needRequest = false
      }

      dispatch('setData', JSON.parse(localStorage.getItem('filtersData')))
    }

    const APECode = getters['APECode/getData']
    const formJuridique = getters['formJuridique/getData']
    const collectiveProcedures = getters['collectiveProcedures/getData']
    const dirigeantStatus = getters['dirigeantStatus/getData']
    if (needRequest
      || !APECode || !APECode.length
      || !formJuridique || !formJuridique.length
      || !collectiveProcedures || !collectiveProcedures.length
      || !dirigeantStatus || !dirigeantStatus.length
    ) {
      dispatch('requestFilters')
    }
  },

  requestFilters ({
    dispatch,
    commit
  }) {
    const result = []

    const loadAPECode = response => {
      const map = new Map()
      const idToText = {}
      for (const item of response) {
        if (!map.has(item.nomenclature_code_activity)) {
          map.set(item.nomenclature_code_activity, new Map())
        }

        if (!map.get(item.nomenclature_code_activity)
          .has(item.group_code)) {
          map.get(item.nomenclature_code_activity)
            .set(item.group_code, new Set())
          idToText[item.nomenclature_code_activity + item.group_code] = item.activity
        }
        if (
          !map.get(item.nomenclature_code_activity)
            .get(item.group_code)
            .has(item.code_activity)
        ) {
          map.get(item.nomenclature_code_activity)
            .get(item.group_code)
            .add(item.code_activity)
          idToText[item.code_activity] = item.code_naf
        }
      }

      for (const x of map) {
        const itemX = {
          title: x[0],
          id: x[0],
          items: []
        }
        for (const y of x[1]) {
          const itemY = {
            title: `${y[0]} - ${idToText[x[0] + y[0]]}`,
            id: `${x[0]}-${y[0]}`,
            items: []
          }
          for (const z of y[1]) {
            const itemZ = {
              title: `${z} - ${idToText[z]}`,
              id: `${x[0]}-${y[0]}-${z}`,
              items: []
            }
            itemY.items.push(itemZ)
          }

          itemX.items.push(itemY)
        }

        result.push(itemX)
      }
      commit('APECode/setData', result)
    }
    const loadFormJuridique = response => {
      const res = []
      for (const x of response) {
        res.push({
          id: x.code,
          title: x.description
        })
      }

      commit('formJuridique/setData', res)
    }
    const loadProceduresCollectives = response => {
      const res = []
      for (const item of response) {
        res.push({
          id: item,
          title: item[0].toUpperCase() + item.substring(1)
        })
      }

      commit('collectiveProcedures/setData', res)
    }
    const loadRepresentantStatus = response => {
      const res = []
      for (const item of response) {
        res.push({
          id: item,
          title: item[0].toUpperCase() + item.substring(1)
        })
      }

      commit('dirigeantStatus/setData', res)
    }

    this.$api.search.filters()
      .then(x => {
        loadAPECode(x.activities)
        loadFormJuridique(x.forme_juridique)
        loadProceduresCollectives(x.procedure_collectives)
        loadRepresentantStatus(x.representant_qualites)

        dispatch('writeLoLocalStorage')
        localStorage.filtersLastSynchro = Math.floor(Date.now() / 1000 / 60 / 60 / 24)
      })
  },
  writeLoLocalStorage ({
    commit,
    getters
  }) {
    const data = getters.getDatas
    const toSave = {}
    for (const i of saveNames) {
      toSave[i] = data[i]
    }
    localStorage.setItem('filtersData', JSON.stringify(toSave))
  },
  writeToQuery ({
    getters,
    commit,
    rootGetters
  }) {
    if (!process.client) return
    const encoders = getters.getEncoders
    const values = getters.getValues
    const res = {}
    for (const i in values) {
      if (values[i] === null) continue

      const temp = encoders[i](values[i])
      if (temp === null || temp === undefined || temp === '') {
        continue
      }
      if (temp instanceof Array) {
        res[`${Service.alias(i)}`] = Service.encoders.ArrayEncode(temp)
      } else if (typeof temp === 'object') {
        for (const local in temp) {
          if (temp[local] instanceof Array) {
            res[`${Service.alias(i)}_${local}`] = Service.encoders.ArrayEncode(temp[local])
          } else {
            res[`${Service.alias(i)}_${local}`] = temp[local]
          }
        }
      } else {
        res[`${Service.alias(i)}`] = temp
      }
    }
    const id = setInterval(() => {
      if (!rootGetters['recherche/loading']) {
        this.$router.push({
          path: this.app.router.currentRoute.path,
          query: res
        })

        clearInterval(id)
      }
    }, 100)

    if (getters.justDropped) {
      commit('setJustDropped', false)
    } else {
      commit('setIgnoreNextQueryUpdate', true)
      clearTimeout(getters.ignoreNextQueryUpdateTimeout)
      commit(
        'setIgnoreNextQueryUpdateTimeout',
        setTimeout(() => {
          commit('setIgnoreNextQueryUpdate', false)
        }, 50)
      )
    }
  }
}
