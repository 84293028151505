<template>
  <div v-if="!isMobile" class="help desktop" :class="{active}">
    <table>
      <colgroup>
        <col>
        <col>
        <col>
        <col>
      </colgroup>
      <tbody>
        <tr class="table-header">
          <th class="title">
            Raison sociale
          </th>
          <th class="title">
            SIREN
          </th>
          <th class="title">
            Adresse
          </th>
          <th class="title">
            {{ params && params.lastColumnTitle || 'Commander' }}
          </th>
        </tr>
        <NuxtLink
          v-for="(item, i) in items"
          :key="i"
          role="row"
          class="tr arrow-button"
          :to="createLinkFunctor(item)"
          @click="_onLinkClick(item)"
        >
          <td class="value name">
            {{ item.company_name }}
          </td>
          <td class="value siren">
            {{ item.siren }}
          </td>
          <td class="value address">
            {{ item.address }}
          </td>
          <td class="value link">
            <NextIcon class="arrow" />
          </td>
        </NuxtLink>
      </tbody>
    </table>
  </div>
  <div v-else class="help mobile" :class="{active}">
    <NuxtLink
      v-for="(item, i) in items"
      :key="i"
      :to="createLinkFunctor(item)"
      class="item"
      @click="_onLinkClick(item)"
    >
      <div class="row">
        <div class="column">
          <div class="value name">
            {{ item.company_name }}
          </div>
          <div class="value siren">
            {{ item.siren }}
          </div>
          <div class="value address">
            {{ item.address }}
          </div>
        </div>
        <div class="icon">
          <NextIcon class="cell-icon" />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script>
import deviceInfo from '~/mixins/deviceInfo'
import NextIcon from '@/assets/svg/arrow-right.svg'

export default {
  components: {
    NextIcon
  },
  mixins: [deviceInfo],
  props: {
    active: {
      default: false
    },
    createLinkFunctor: {
      default: () => item => `/entreprise/${item.url}`
    },
    onLinkClick: {
      default: undefined
    },
    params: {
      default: null
    },
    items: {
      default: () => []
    }
  },
  methods: {
    _onLinkClick (item) {
      if (this.onLinkClick) {
        this.onLinkClick(item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  transform-origin: top;
  background: var(--white);
  padding-bottom: 16px;
  padding-top: 30px;
  border-radius: 0 0 40px 40px;
  margin-top: calc(31px);
  animation-duration: 0.25s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-name: rolling-vertical-out, disappearing;
  width: 100%;
  box-shadow: 0px 100px 80px rgba(115, 132, 156, 0.07),
    0px 64.8148px 46.8519px rgba(115, 132, 156, 0.0531481),
    0px 38.5185px 25.4815px rgba(115, 132, 156, 0.0425185),
    0px 20px 13px rgba(115, 132, 156, 0.035),
    0px 8.14815px 6.51852px rgba(115, 132, 156, 0.0274815),
    0px 1.85185px 3.14815px rgba(115, 132, 156, 0.0168519);

  &.active {
    animation-name: rolling-vertical-in, appearing;
  }

  &.mobile {
    .row {
      display: flex;
      justify-content: space-between;
    }

    .column {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    .item {
      position: relative;
      padding: 20px 24px;
      display: block;
      @include normal-font;
      word-wrap: break-word;
      text-decoration: none;
      transition: all 0.25s ease;

      .row {
        gap: 5px;
      }

      .column {
        gap: 2px;
      }

      .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .value {
        &.address {
          @include small-font;
          color: var(--light-gray);
        }
      }

      &:hover,
      &:focus {
        background: var(--background-gray);
      }
    }
  }

  .value {
    color: var(--paragraph);
    font-weight: 600;
    width: 100%;

    &.name {
      color: var(--brand);
      font-weight: 400;
    }

    &.siren {
    }

    .arrow-link {
      line-height: 0;
      display: inline-block;
      margin: -20px;
      padding: 20px;
    }
  }

  .icon {
    --svg-color: var(--title);
  }

  table {
    border-top: 1px solid var(--separator);
    border-spacing: 0;
    table-layout: fixed;

    .table-header {
      th {
        color: var(--light-gray);
        @include small-font;
        padding-right: 5px;
        padding-top: 20px;
        padding-bottom: 16px;
        text-align: left;
        font-weight: 400;

        &:last-child {
          padding-right: 24px;
          text-align: right;
        }

        &:first-child {
          padding-left: 24px;
        }
      }
    }

    td {
      transition: all 0.25s ease;
      word-wrap: break-word;
      padding-right: 5px;
      padding-top: 26px;
      padding-bottom: 26px;

      &:last-child {
        padding-right: 24px;
        text-align: right;
      }

      &:first-child {
        padding-left: 24px;
      }

      .icon {
        --svg-color: var(--title);
      }
    }

    .tr:hover,
    .tr:focus {
      td {
        background: var(--background-gray);
      }
    }

    .tr {
      display: table-row;
      text-decoration: none;
    }

    td,
    th,
    col {
      &:nth-child(1) {
        min-width: 25%;
        max-width: 25%;
      }

      &:nth-child(2) {
        min-width: 25%;
        max-width: 25%;
      }

      &:nth-child(3) {
        min-width: 44%;
        max-width: 44%;
      }

      &:nth-child(4) {
        min-width: 10%;
        max-width: 10%;
      }
    }
  }
}

@keyframes scaling-vertical-in {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes scaling-vertical-out {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
</style>
