// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Open Sans/open-sans-v34-400.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Open Sans/open-sans-v34-400.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Open Sans/open-sans-v34-600.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/Open Sans/open-sans-v34-600.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/Open Sans/open-sans-v34-700.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/Open Sans/open-sans-v34-700.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/Poppins/poppins-v20-400.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../fonts/Poppins/poppins-v20-400.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../fonts/Poppins/poppins-v20-500.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../fonts/Poppins/poppins-v20-500.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../fonts/Poppins/poppins-v20-600.woff2");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../fonts/Poppins/poppins-v20-600.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Open Sans\";src:local(\"Open Sans\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-display:swap;font-style:normal}@font-face{font-family:\"Open Sans\";src:local(\"Open Sans\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:600;font-display:swap;font-style:normal}@font-face{font-family:\"Open Sans\";src:local(\"Open Sans\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:700;font-display:swap;font-style:normal}@font-face{font-family:\"Poppins\";src:local(\"Poppins\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\");font-weight:400;font-display:swap;font-style:normal}@font-face{font-family:\"Poppins\";src:local(\"Poppins\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"woff\");font-weight:500;font-display:swap;font-style:normal}@font-face{font-family:\"Poppins\";src:local(\"Poppins\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"woff\");font-weight:600;font-display:swap;font-style:normal}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet-landscape": "1160px",
	"tablet-portrait": "900px",
	"phone": "640px"
};
module.exports = ___CSS_LOADER_EXPORT___;
