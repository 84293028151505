import Api from './Api'

class SiteClones extends Api {
  constructor (_axios) {
    super(_axios, '/site-clones')
  }

  getByMerchantDomain (domain) {
    return this.get(`/by-merchant-domain/${domain}`)
  }
}

export default SiteClones
