export function normalizeEstablishments (arr) {
  const fields = [
    'activity',
    'denomination',
    'address',
    'date_creation',
    'is_active',
    'siret',
    'signboard_1',
    'signboard_2',
    'signboard_3'
  ]
  normalizeArrayOfObjects(arr, fields)
}
export function normalizeBodacc (arr) {
  const fields = [
    'activity',
    'address',
    'administration',
    'card_title',
    'closing_date',
    'date_release',
    'denomination',
    'deposit_type',
    'description',
    'full_url',
    'card_type',
    'judgment_complement',
    'judgment_family',
    'judgment_nature',
    'number_announced',
    'rcs_code',
    'registration_city',
    'release'
  ]
  normalizeArrayOfObjects(arr, fields)
}

export function normalizeBodaccPCL (obj) {
  const nonGroupFields = [
    'card_type',
    'release',
    'number_announced',
    'closing_date',
    'judgment_family',
    'judgment_nature',
    'full_url'
  ]
  obj.group.forEach(group => normalizeArrayOfObjects(group.items, nonGroupFields))
  normalizeArrayOfObjects(obj.non_group, nonGroupFields)
}

export function normalizeFinanceReports (arr) {
  const fields = [
    'added_value',
    'added_value_turnover',
    'average_number_of_staff',
    'coverage_of_working_capital_requirements',
    'currency_code',
    'customer_payment_deadline',
    'debt_coverage',
    'dues_and_taxes',
    'economic_profitability',
    'financial_autonomy',
    'financial_debts',
    'general_liquidity',
    'global_net_working_capital',
    'gross_margin',
    'gross_margin_rate',
    'gross_operating_surplus',
    'gross_operating_surplus_margin_rate',
    'leverage_rate',
    'need_in_funds',
    'need_in_funds_rate',
    'net_margin',
    'net_profit',
    'non_operating_working_capital_requirement',
    'non_operating_working_capital_requirement_rate',
    'operating_margin_rate',
    'operating_result',
    'operating_working_capital_requirement',
    'operating_working_capital_requirement_rate',
    'profitability_on_equity',
    'rate_of_debt',
    'ratio_des_stocks_ca',
    'repayment_capacity',
    'salaries_and_social_charges',
    'salaries_turnover',
    'self_financing_capacity',
    'self_financing_capacity_ca_rate',
    'statement_of_debts_at_1_year_at_most',
    'supplier_payment_deadline',
    'treasury',
    'turnover',
    'turnover_growth_rate',
    'year'
  ]

  normalizeArrayOfObjects(arr, fields)
}

export function normalizeIsinFinanceReports (arr) {
  const fields = [
    'file_title',
    'ftp_file_path',
    'inf_cod_dif',
    'isin',
    'iso_cd_amf',
    'iso_cd_mch',
    'iso_cd_tkr',
    'lei',
    'send_date_amf',
    'send_date_market',
    'sub_type_of_information_fr',
    'transmission_date',
    'type_information_new_fr',
    'type_information_old_fr'
  ]

  normalizeArrayOfObjects(arr, fields)
}

export function normalizeSimilarCompanies (arr) {
  const fields = [
    'alias',
    'company_name'
  ]

  normalizeArrayOfObjects(arr, fields)
}
export function normalizeJuristicDocuments (arr) {
  const fields = [
    'document_date_final',
    'document_date_signature',
    'document_date_start',
    'document_date_update',
    'document_number',
    'document_siret',
    'document_themes',
    'document_title',
    'document_union',
    'document_url'
  ]

  normalizeArrayOfObjects(arr, fields)
}
export function normalizeObservations (arr) {
  const fields = [
    'date_greffe',
    'description',
    'etat',
    'mention_date',
    'num'
  ]
  normalizeArrayOfObjects(arr, fields)
}

export function normalizeRepresentants (arr) {
  const fields = [
    'date_greffe',
    'date_of_birth',
    'has_collective_procedure',
    'hidden',
    'is_physique',
    'name',
    'qualite',
    'siren',
    'url'
  ]

  normalizeArrayOfObjects(arr, fields)
}
export function normalizeBeneficiaires (arr) {
  const fields = [
    'administrative_control',
    'administrative_control_hr',
    'another_control',
    'calc_voc_titu_indir_nue',
    'calc_voc_titu_indir_pleine',
    'calc_vote_indirecte_indivision',
    'date_greffe',
    'date_of_birth',
    'detent_vote_indirect_pmorales',
    'has_collective_procedure',
    'hidden',
    'legal_representant',
    'legal_representant_collective',
    'legal_unite_type',
    'name',
    'parts_directes_nue_propriete',
    'parts_directes_pleine_propr',
    'parts_indir_pmoral_pleine_prop',
    'parts_indir_pmorales_nue_prop',
    'parts_indirec_ind_pleine_propr',
    'parts_indirectes_ind_nue_propr',
    'parts_indirectes_indivision',
    'parts_indirectes_pmorales',
    'raw_voc_titu_indir_indivision',
    'stock_direct_total',
    'stock_indirect_total',
    'stock_total',
    'voc_direct_total',
    'voc_indirect_total',
    'voc_titu_direct_nue_propriete',
    'voc_titu_direct_pleine_prop',
    'voc_titu_indir_nue_prop',
    'voc_titu_indir_pleine_prop',
    'voc_titu_indir_pmor_nue_prop',
    'voc_titu_indir_pmor_plein_prop',
    'voc_titu_indir_pmorales',
    'voc_total',
    'vote_direct_nue_propriete',
    'vote_direct_pleine_propriete',
    'vote_direct_usufruit',
    'vote_indir_ind_nue_propriete',
    'vote_indir_ind_pleine_prop',
    'vote_indir_ind_usufruit',
    'vote_indir_pmoral_plein_propr',
    'vote_indir_pmorales_nue_propr',
    'vote_indir_pmorales_usufruit',
    'votes_direct_total',
    'votes_indirect_total',
    'votes_total'
  ]

  normalizeArrayOfObjects(arr, fields)
}
export function normalizeBrands (arr) {
  const fields = [
    'application_number',
    'classes',
    'expiration_date',
    'image_url',
    'is_active',
    'name',
    'next_number',
    'registration_date',
    'type'
  ]

  normalizeArrayOfObjects(arr, fields)
}

function normalizeArrayOfObjects (arr, fields) {
  if (!arr) return
  arr.forEach(item => Object.keys(item).forEach(key => {
    // eslint-disable-next-line no-param-reassign
    if (!fields.includes(key)) delete item[key]
  }))
}

export function normalizeArrayFieldsInObject (object, fields) {
  fields.forEach(key => {
    if (!object[key] || typeof object[key] !== 'object') {
      object[key] = []
      return
    }
    if (Array.isArray(object[key])) {
      object[key] = object[key].filter(item => item !== null)
    } else {
      object[key] = []
    }
  })
}
