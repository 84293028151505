<template>
  <header class="header-wrapper">
    <div class="left-side">
      <BrandLogo />
      <HeaderSearchBar class="desktop-header-search-bar" />
      <LinksList />
    </div>
    <div class="right-side">
      <PhoneNumber />
      <CButton border-type="minimum-rounded" class="login-button" @click="action">
        <template v-if="!user">
          <UserIcon />
          <span class="login-text">Login</span>
        </template>
        <template v-else>
          <LogoutIcon style="margin-right: -4px;" fill="white" width="18" height="20" />
          <span class="login-text">Logout</span>
        </template>
      </CButton>
    </div>
  </header>
</template>

<script>
import UserIcon from '@/assets/svg/icons/user.svg'
import LogoutIcon from '@/assets/svg/icons/logout.svg'
import CButton from '../common/CButton.vue'
import BrandLogo from './parts/BrandLogo.vue'
import PhoneNumber from './parts/PhoneNumber.vue'
import LinksList from './parts/LinksList.vue'
import HeaderSearchBar from './parts/HeaderSearchBar.vue'

export default {
  name: 'MainHeaderDesktop',
  components: {
    BrandLogo,
    CButton,
    UserIcon,
    PhoneNumber,
    LinksList,
    HeaderSearchBar,
    LogoutIcon

  },
  computed: {
    user () {
      return this.$store.getters['user/index']
    }
  },
  methods: {
    action () {
      if (!this.user) {
        this.openLoginModal()
      } else {
        this.logout()
      }
    },
    openLoginModal () {
      this.$modals.showModal(this.$modals.names.LoginModal)
    },
    logout () {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  max-height: 56px;
  max-width: 1210px;
  width: 100vw;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;

  .left-side {
    @include flex-line-start;
  }

  .right-side {
    @include flex-line-end;
    padding: 11px 0;
    max-height: 100%;
  }

  .login-button {
    gap: 10px;
    margin-left: 20px;
    padding: 0 16px;
    min-width: 94px;
    min-height: 34px;
    width: auto;
    max-height: 34px;
    --svg-color: var(--white);
    @include flex-line-center;

    .login-text {
      text-transform: uppercase;
      font-weight: 600;
      @include very-small-font;
    }
  }

  .desktop-header-search-bar {
    margin: 0 30px;
  }
}
</style>
