import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state(),
  data: [
    {
      title: 'Adhésion au CAC40',
      id: 'cac40'
    }, {
      title: 'Entreprises en croissance',
      id: '1'
    }, {
      title: 'Entreprises en décroissance',
      id: '2'
    }, {
      title: 'Est propriétaire de la marque',
      id: 'has_brands'
    }
  ]

})
export const getters = {
  ...defaultFilter.getters,
  mapper: state => value => {
    if (value === null) return null
    const res = {}
    Object.defineProperty(res, 'expand', {
      value: true,
      enumerable: false,
      writable: false
    })
    if (value.includes('cac40')) res.cac40 = 1
    if (value.includes('has_brands')) res.has_brands = 1
    if (value.includes('1')) res.turnover_growth_rate_from = 0
    if (value.includes('2')) res.turnover_growth_rate_to = 0
    return res
  }

}
export const mutations = {
  ...defaultFilter.mutations

}
