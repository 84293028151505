<template>
  <CModal
    :show-time="MOBILE_MENU_ANIMATION_TIME"
    :hide-time="MOBILE_MENU_ANIMATION_TIME"
    :animation-type="SLIDE_TOP"
    :modal-name="$modals.names.MobileMenuModal"
    :custom="true"
  >
    <div class="mobile-menu-modal">
      <HeaderSearchBar class="search-bar search-bar-in-menu" variation="mobile-menu" />
      <LinksList orientation="horizontal" class="links-list" @click="linkClick" />
      <div class="phone-and-button">
        <CButton border-type="minimum-rounded" class="login-button" @click="action">
          <template v-if="!user">
            <UserIcon />
            <span class="login-text">Login</span>
          </template>
          <template v-else>
            <LogoutIcon style="margin-right: -4px; width: 18px" fill="white" height="26" />
            <span class="login-text">Logout</span>
          </template>
        </CButton>
        <PhoneNumber class="phone" size="big" />
      </div>
    </div>
  </CModal>
</template>

<script>
import UserIcon from '@/assets/svg/icons/user.svg'
import CButton from '@/components/common/CButton.vue'
import HeaderSearchBar from '@/components/layout/parts/HeaderSearchBar.vue'
import LinksList from '@/components/layout/parts/LinksList.vue'
import PhoneNumber from '@/components/layout/parts/PhoneNumber.vue'
import LogoutIcon from '@/assets/svg/icons/logout.svg'
import userInfo from '~/mixins/userInfo'
import CModal from '~/components/modals/CModal'

import { SLIDE_TOP } from '@/components/modals/CModal'

export const MOBILE_MENU_ANIMATION_TIME = 250
export default {
  name: 'MobileMenuModal',
  components: {
    HeaderSearchBar,
    LinksList,
    PhoneNumber,
    CButton,
    UserIcon,
    LogoutIcon,
    CModal
  },
  mixins: [userInfo],
  data () {
    return {
      MOBILE_MENU_ANIMATION_TIME,
      SLIDE_TOP
    }
  },
  methods: {
    linkClick (e) {
      if (!e.hasInnerItems) {
        this.$modals.hideModal(this.$modals.names.MobileMenuModal)
      }
    },
    action () {
      this.$modals.hideModal(this.$modals.names.MobileMenuModal)
      if (!this.user) {
        setTimeout(() => {
          this.$modals.showModal(this.$modals.names.LoginModal)
        }, MOBILE_MENU_ANIMATION_TIME)
      } else {
        this.$store.dispatch('auth/logout')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-menu-modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 56px;
  bottom: 0;
  background: var(--white);
  transform: none;
  animation: opening var(--show-time) ease 0s 1 normal forwards;
  padding: 24px 20px 40px;
  @include flex-line-start;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: max-content;

  @media (max-height: 620px) {
    .login-button,
    .search-bar {
      width: calc(100vw - 40px - 4px);
    }
  }

  .login-button {
    gap: 10px;
    padding: 0 12px;
    margin-top: 30px;
    min-width: 94px;
    min-height: 52px;
    width: 170px;
    --svg-color: currentColor;
    @include normal-alt-font;

    .login-text {
      text-transform: uppercase;
      font-weight: 600;
    }

    svg {
      width: 15px;
      height: auto;
    }
  }

  .search-bar {
    margin-right: auto;
    margin-left: auto;
    max-width: calc(100vw - 40px);
    margin-bottom: 40px;
    min-height: 52px;

    @media (min-width: 641px) {
      display: none;
    }
  }

  .links-list {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 75px;
    @include normal-alt-font;
  }

  .phone {
    margin-top: 30px;
  }

  .phone-and-button {
    display: flex;
    flex-direction: column;
    width: max-content;
    align-items: center;
  }

  @media (max-width: 640px) {
    align-items: flex-start;

    .links-list {
      gap: 45px;
      flex-direction: column;
    }

    .login-button {
      order: 1;
      width: 100%;
    }

    .phone {
      margin-top: 60px;
    }
  }

  @media (max-width: 640px) {
    height: 95%;
    .phone-and-button {
      width: 100%;
      margin-top: auto;
      align-items: flex-start;

      .phone {
        margin-top: 45px;
      }
    }
  }
}
</style>
