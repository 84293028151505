import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters
}
export const mutations = {
  ...defaultFilter.mutations

}
