export default {
  computed: {
    breakpoints () {
      return this.$store.getters['device/breakpoints']
    },
    windowWidth () {
      return this.$store.getters['device/windowWidth']
    },
    windowHeight () {
      return this.$store.getters['device/windowHeight']
    },
    isMobile () {
      if (process.server) {
        return this.$store.getters['device/isMobile']
      }
      return this.windowWidth <= this.breakpoints['tablet-landscape']
    },
    isPhone () {
      if (process.server) {
        return this.$store.getters['device/isPhone']
      }

      return this.windowWidth <= this.breakpoints.phone
    }
  }
}
