import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state()
})

export const getters = {
  ...defaultFilter.getters,
  encoder: state => value => {
    const isEncodered = {}
    for (const key in value) {
      if (value[key].length) {
        isEncodered[key] = value[key].map(el => el)
      }
    }
    if (!Object.keys(isEncodered).length) return null
    return isEncodered
  },
  mapper: state => value => {
    const queryParams = {}
    Object.defineProperty(queryParams, 'expand', {
      value: true,
      enumerable: false,
      writable: false
    })
    for (const key in value) {
      if (value[key].length) {
        if (key === 'communes') {
          queryParams.city_code = value[key].map(el => el)
        }
        if (key === 'departments') {
          queryParams.department_code = value[key].map(el => el)
        }
        if (key === 'regions') {
          queryParams.region_code = value[key].map(el => el)
        }
      }
    }
    if (!Object.keys(queryParams).length) return null
    return queryParams
  }
}

export const mutations = {
  ...defaultFilter.mutations
}
