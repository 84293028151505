import modalNames from '~/modals/modalNames'

const MODAL_EVENT_PREFIX = 'modal-bus'
const SHOW_EVENT_PREFIX = `${MODAL_EVENT_PREFIX}-show`
const HIDE_EVENT_PREFIX = `${MODAL_EVENT_PREFIX}-hide`

const modalsMountState = Object.keys(modalNames).reduce((finalObject, modalKey) => ({ ...finalObject, [modalKey]: false }), {})
const keepAliveArray = [
  modalNames.BaseFiltersModal,
  modalNames.AllFiltersModal,
  modalNames.FinancesFiltersModal,
  modalNames.PersonsFiltersModal,
  modalNames.IntelligentsFiltersModal,
  modalNames.MobileFiltersModal
]

export const functional = {
  names: modalNames,
  anyModal: false,
  openedModals: [],
  keepAlive: {
    array: keepAliveArray,
    current: null
  },
  showModal (name, params = {}) {
    const isKeepAlive = this.keepAlive.array.includes(name)

    if (!this.__modalsMountState[name]) {
      this.__lastPremountCalledModalEvent = {
        name,
        params
      }
      if (!isKeepAlive) {
        return
      }
    }

    if (!this.openedModals.includes(name)) this.openedModals.push(name)
    if (isKeepAlive) {
      this.keepAlive.current = name
    }
    if (!this.anyModal) {
      this.anyModal = true
    }

    window.$nuxt.$emit(`${SHOW_EVENT_PREFIX}-${name}`, params)
  },
  hideModal (name, params) {
    window.$nuxt.$emit(`${HIDE_EVENT_PREFIX}-${name}`, params)

    if (this.openedModals.length && this.openedModals.includes(name)) {
      this.openedModals = this.openedModals
        .filter(modalName => modalName !== name)
    }
    if (this.openedModals.length === 0) {
      this.anyModal = false
    }

    this.keepAlive.current = null
  },
  hideTopModal () {
    if (!this.openedModals.length) return

    const name = this.openedModals.pop()
    this.hideModal(name)
  },
  subscribe (name, { show, hide }) {
    window.$nuxt.$on(`${SHOW_EVENT_PREFIX}-${name}`, show)
    window.$nuxt.$on(`${HIDE_EVENT_PREFIX}-${name}`, hide)

    this.__modalsMountState[name] = true
    if (this.__lastPremountCalledModalEvent && this.__lastPremountCalledModalEvent.name === name) {
      this.showModal(this.__lastPremountCalledModalEvent.name, this.__lastPremountCalledModalEvent.params)
      this.__lastPremountCalledModalEvent = null
    }
  },
  unsubscribe (name, { show, hide }) {
    window.$nuxt.$off(`${SHOW_EVENT_PREFIX}-${name}`, show)
    window.$nuxt.$off(`${HIDE_EVENT_PREFIX}-${name}`, hide)
  }
}

// объявляю поля так, чтобы они не показывались в подсказках
Object.defineProperty(functional, '__modalsMountState', {
  value: modalsMountState,
  enumerable: false
})
// объявляю поля так, чтобы они не показывались в подсказках
Object.defineProperty(functional, '__lastPremountCalledModalEvent', {
  value: null,
  enumerable: false,
  writable: true
})

export default {
  install (Vue) {
    Vue.prototype.$modals = Vue.observable(functional)
  }
}
