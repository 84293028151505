/* eslint-disable */
import { ADS_FIRST_KEY, ADS_RAW_KEY } from '~/store/statistics'

const CUSTOM = {
  campaign: 'isTRX'
}

const terms = {
  traffic: {
    utm: 'utm',
    organic: 'organic',
    referral: 'referral',
    typein: 'typein'
  },

  referer: {
    referral: 'referral',
    organic: 'organic',
    social: 'social'
  },

  none: '(none)',
  oops: '(Houston, we have a problem)'
}

let __g_source = terms.none

const result = {
  delimiter: '|||',

  aliases: {
    main: {
      type: 'typ',
      source: 'src',
      medium: 'mdm',
      campaign: 'cmp',
      content: 'cnt',
      term: 'trm'
    }
  },

  main (sbjs) {
    return (
      `${result.aliases.main.type}=${sbjs.type}${result.delimiter
      }${result.aliases.main.source}=${sbjs.source}${result.delimiter
      }${result.aliases.main.medium}=${sbjs.medium}${result.delimiter
      }${result.aliases.main.campaign}=${sbjs.campaign}${result.delimiter
      }${result.aliases.main.content}=${sbjs.content}${result.delimiter
      }${result.aliases.main.term}=${sbjs.term}`
    )
  }
}

export function getAds (query) {
  const data = getData(query)

  if (!localStorage.getItem(ADS_FIRST_KEY)) {
    localStorage.setItem(ADS_FIRST_KEY, JSON.stringify(data))
  }

  return {
    ads_raw: data,
    ads: result.main(data)
  }
}

function getData (query) {
  let type = terms.none
  let source = terms.none
  let medium = terms.none
  let campaign = terms.none
  let content = terms.none
  let term = terms.none

  if (
    typeof query.utm_source !== 'undefined'
    || typeof query.utm_medium !== 'undefined'
    || typeof query.utm_campaign !== 'undefined'
    || typeof query.utm_term !== 'undefined'
    || typeof query.utm_content !== 'undefined'
    || typeof query.gclid !== 'undefined'
    || typeof query.yclid !== 'undefined'
  ) {
    type = terms.traffic.utm

    if (typeof query.utm_source !== 'undefined') {
      source = query.utm_source
    } else if (typeof query.gclid !== 'undefined') {
      source = 'google'
    } else if (typeof query.yclid !== 'undefined') {
      source = 'yandex'
    }

    if (typeof query.utm_medium !== 'undefined') {
      medium = query.utm_medium
    } else if (typeof query.gclid !== 'undefined' || typeof query.yclid !== 'undefined') {
      medium = 'cpc'
    }

    if (typeof query.utm_campaign !== 'undefined') {
      campaign = query.utm_campaign
    }
    // else if (typeof query[CUSTOM.campaign] !== 'undefined') {
    //   campaign = CUSTOM.campaign
    // }
    else if (typeof query.gclid !== 'undefined') {
      campaign = 'google_cpc'
    } else if (typeof query.yclid !== 'undefined') {
      campaign = 'yandex_cpc'
    }

    if (typeof query.utm_content !== 'undefined') {
      content = query.utm_content
    }

    if (typeof query.utm_term !== 'undefined') {
      term = query.utm_term
    }
  } else if (checkReferrer(terms.traffic.organic)) {
    type = terms.traffic.organic
    source = __g_source || getDomain(document.referrer)
    medium = terms.traffic.organic
    campaign = terms.none
    content = terms.none
    term = terms.none
  } else if (checkReferrer(terms.traffic.referral)) {
    type = terms.traffic.referral
    source = __g_source || getDomain(document.referrer)
    medium = terms.traffic.referral
    campaign = terms.none
    content = getPath(document.referrer)
    term = terms.none
  } else if (!localStorage.getItem(ADS_RAW_KEY) && !localStorage.getItem(ADS_FIRST_KEY)) {
    type = terms.traffic.typein
    source = '(direct)'
    medium = terms.none
    campaign = terms.none
    content = terms.none
    term = terms.none
  } else if (localStorage.getItem(ADS_RAW_KEY)) {
    return JSON.parse(localStorage.getItem(ADS_RAW_KEY))
  }

  return {
    type, source, medium, campaign, content, term
  }
}

function checkReferrer (type) {
  const { referrer } = document
  switch (type) {
    case terms.traffic.organic:
      return !!referrer && checkRefererHost(referrer) && isOrganic(referrer)
    case terms.traffic.referral:
      return (!!referrer && checkRefererHost(referrer) && isReferral(referrer))
    default:
      return false
  }
}

function getDomain (url) {
  return (new URL(url)).hostname
}

function getPath (url) {
  return (new URL(url)).pathname
}

function checkRefererHost (referrer) {
  return getDomain(referrer) !== getDomain(document.location.href)
}

function isOrganic (referer) {
  const y_host = 'yandex'
  const g_host = 'google'

  if (referer.includes(y_host)) {
    __g_source = y_host

    return true
  }
  if (referer.includes(g_host)) {
    __g_source = g_host

    return true
  }

  return false
}

function isReferral (referer) {
  __g_source = getDomain(referer)

  return true
}
