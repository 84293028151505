export const state = () => ({
  notifications: []
})

export const getters = {
  getNotifications (state) {
    return state.notifications
  }
}

export const mutations = {
  addNotifications (state, notification) {
    state.notifications.push(notification)
  },
  deleteNotification (state, index) {
    state.notifications.splice(index, 1)
  }
}

export const actions = {
  addNotifications ({ commit }, notifications) {
    commit('addNotifications', notifications)
  },
  popNotification ({ commit, getters }) {
    const item = getters.getNotifications[0]
    commit('deleteNotification', 0)

    return item
  }
}
