import * as defaultFilter from '@/services/defaultFilter.js'
import MathUtils from '~/services/MathUtils'
import service from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state(),
  default: {
    capital: 1,
    establishments_count: 1,
    net_profit: 1,
    staff: 1,
    turnover: 1
  }

})
export const getters = {
  ...defaultFilter.getters,
  encoder: state => value => {
    if (value === null) return null
    const res = {}
    for (const i in value) {
      if (value[i] < 0.99 || value[i] > 1.01) res[service.alias(i)] = MathUtils.clamp(+value[i], 0, 2)
    }
    return res
  },
  decoder: state => value => {
    const res = {}
    for (const i in state.default) {
      if (value[i] === undefined || value[i] === null) {
        res[i] = state.default[i]
      } else {
        res[i] = value[i]
      }
    }
    return res
  },
  mapper: state => value => {
    const _value = {}
    for (const i in value) {
      if (value[i] < 0.99 || value[i] > 1.01) _value[i] = value[i]
    }
    return _value
  }

}
export const mutations = {
  ...defaultFilter.mutations

}
