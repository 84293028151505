export const state = () => ({
  information: null,
  counters: null
})

export const getters = {
  trialPrice (state) {
    return state.information ? `${state.information.trial_price}` : '1.90'
  },
  mainPrice (state) {
    return state.information ? `${state.information.main_price}` : '39.90'
  },
  counters (state) {
    return state.counters
  }
}

export const actions = {
  async load ({ commit }) {
    commit('setInformation', await this.$api.meta.loadInformation())
  },
  async loadCounters ({ commit }) {
    commit('counters', await this.$api.services.counters())
  }
}

export const mutations = {
  setInformation (state, data) {
    state.information = data
  },
  counters (state, data) {
    state.counters = data
  }
}
