<template>
  <div
    class="search-input-wrapper active"
    :class="{active:isActive && searchString && items.length, ...classes}"
    :style="currentVariation.padding ? `--input-padding-right: ${currentVariation.padding}px` : null"
    role="search"
    @mouseenter="mouseEnter"
  >
    <label class="input-label">
      <span class="input-wrapper">
        <span style="display:block;">
          <SearchIcon class="search-icon" />
        </span>
        <input
          ref="input"
          :value="searchString"
          :placeholder="placeholder || currentVariation.placeholder"
          class="search-input"
          type="search"
          @input="handleInput"
          @keydown="keypress"
          @focusin="focusIn"
          @focusout="focusOut"
        >
      </span>
    </label>
    <CButton
      class="button"
      border-type="rounded"
      :aria-label="currentVariation.buttonText"
      @click="buttonClick"
    >
      <span v-if="currentVariation.buttonText" class="button-text">
        {{ currentVariation.buttonText }}
      </span>
      <NextIcon
        v-if="designVariation === ALPHA_TYPE || designVariation === BASE_TYPE || designVariation === THANK_YOU_PAGE_TYPE"
        class="next-icon"
      />
      <ChevronRight v-else-if="betaErrorVariation" class="next-icon" />
    </CButton>
    <Suggest
      v-if="!isCustom"
      :q="searchString"
      :params="{
        lastColumnTitle: currentVariation.lastColumnTitle
      }"
      :on-link-click="onLinkClick"
      :create-link-functor="createLinkFunctor"
      :active="isSuggest"
      :items="items"
      :type="suggestType"
      :select-index="selectIndex"
      @click="clickSuggest"
    />
  </div>
</template>

<script>
import ChevronRight from 'assets/svg/chevron-right-alt.svg'
import Suggest from './SearchInputSuggest/Suggest'
import SearchIcon from '@/assets/svg/search.svg'
import CButton from '@/components/common/CButton'
import NextIcon from '@/assets/svg/arrow-right.svg'
import deviceInfo from '@/mixins/deviceInfo'
import {
  MIN_SEARCH_LENGTH, BASE_TYPE, CUSTOM_TYPE, ERROR_PAGE_TYPE, ALPHA_TYPE, BETA_TYPE, THANK_YOU_PAGE_TYPE
} from '~/services/consts'

export default {
  name: 'SearchInput',
  components: {
    Suggest,
    SearchIcon,
    CButton,
    NextIcon,
    ChevronRight
  },
  mixins: [deviceInfo],
  props: {
    variation: {
      default: BASE_TYPE,
      validator (value) {
        return ['kbis', BASE_TYPE, 'tva', 'avis', 'notapme', 'premium', CUSTOM_TYPE].includes(value)
      }
    },
    custom: {
      default: null
    },
    designVariation: {
      default: BASE_TYPE,
      validator (value) {
        return [ALPHA_TYPE, BETA_TYPE, BASE_TYPE, ERROR_PAGE_TYPE, THANK_YOU_PAGE_TYPE].includes(value)
      }
    },
    placeholder: {
      default: null
    },
    createLinkFunctor: {
      default: () => item => `/entreprise/${item.url}`
    },
    onLinkClick: {
      default: undefined
    },
    searchText: {
      default: ''
    },
    limit: {
      default: 3
    }
  },
  data () {
    return {
      searchString: null,
      isActive: false,
      focused: false,
      mouseIn: false,
      focusedElements: [],
      items: [],
      selectIndex: -1,
      variations: {
        main: {
          buttonText: 'Rechercher',
          placeholder: 'Nom entreprise, SIREN, Dirigeant...',
          lastColumnTitle: 'Comander'
        },
        kbis: {
          buttonText: 'Télécharger KBIS',
          placeholder: 'nom de l\'entreprise / siren/ Siret ...',
          lastColumnTitle: 'Commander le KBIS',
          padding: 230
        },
        tva: {
          buttonText: 'Voir N° TVA',
          placeholder: 'nom de l\'entreprise / siren/ Siret ...',
          lastColumnTitle: 'Comander',
          padding: 190
        },
        avis: {
          buttonText: 'Télécharger AVIS SIRENE',
          placeholder: 'nom de l\'entreprise / siren/ Siret ...',
          lastColumnTitle: 'Commander le Avis SIRENE',
          padding: 290
        },
        notapme: {
          buttonText: 'Télécharger diagnostic',
          placeholder: 'nom de l\'entreprise / siren/ Siret ...',
          lastColumnTitle: 'Commander',
          padding: 270
        }
      }
    }
  },
  computed: {
    suggestType () {
      return this.variation === BASE_TYPE ? this.variation : this.designVariation
    },
    isSuggest () {
      return this.isActive && this.items.length
    },
    THANK_YOU_PAGE_TYPE () {
      return THANK_YOU_PAGE_TYPE
    },
    BASE_TYPE () {
      return BASE_TYPE
    },
    ALPHA_TYPE () {
      return ALPHA_TYPE
    },
    betaErrorVariation () {
      return [BETA_TYPE, ERROR_PAGE_TYPE].includes(this.designVariation)
    },
    isCustom () {
      return this.variation === CUSTOM_TYPE
    },
    currentVariation () {
      if (this.isCustom) {
        return this.custom
      }
      return this.variations[this.variation]
    },
    classes () {
      const res = {}
      if (this.designVariation === ALPHA_TYPE) {
        res[BASE_TYPE] = true
        return res
      }
      if (this.designVariation) res[this.designVariation.toLowerCase()] = true

      return res
    }
  },
  watch: {
    searchText (v) {
      this.searchString = v
    }
  },
  created () {
    this.searchString = this.searchText
    this.$set(this.variations, 'premium', this.variations.main)
    if (this.searchString && this.searchString.length) {
      this.loadHelp(this.searchString)
    }
  },
  beforeMount () {
    document.addEventListener('focusin', this.focusChanged)
  },
  beforeDestroy () {
    document.removeEventListener('focusin', this.focusChanged)
  },
  methods: {
    focusChanged (event) {
      this.focusedElements.push(event.target)
      if (this.focusedElements.length === 3) {
        this.focusedElements.shift()
      }
    },
    delay (ms) {
      return new Promise(resolve => {
        this.hideTimer = setTimeout(resolve, ms)
      })
    },
    buttonClick (e) {
      const func = () => {
        if (this.items.length === 1 && this.variation === BASE_TYPE) {
          this.instantNavigation()
        } else if (this.items.length === 1) {
          this.$router.push(this.createLinkFunctor(this.items[0]))
        } else {
          this.$emit('search', { q: this.searchString })
          e.target.closest('button').blur()
        }
      }
      if (this.focusedElements[0] === this.$refs.input) {
        this.focusOut().then(func)
      } else {
        func()
      }
    },
    keypress (e) {
      if (e.key.toLowerCase() === 'enter') {
        if (this.items.length === 1 && this.variation === BASE_TYPE) {
          this.instantNavigation()
        } else {
          if (this.items.length === 1) {
            this.$router.push(this.createLinkFunctor(this.items[0]))
          } else {
            this.$emit('search', { q: this.searchString })
          }
          this.focusOut()
        }
      } else if (this.variation === BASE_TYPE) {
        if (e.key.toLowerCase() === 'arrowdown') {
          e.preventDefault()
          this.chooseSuggest(1)
        } else if (e.key.toLowerCase() === 'arrowup') {
          e.preventDefault()
          this.chooseSuggest(-1)
        }
      }
    },
    clickSuggest (e) {
      if (this.variation === BASE_TYPE) {
        this.selectIndex = e
        this.searchString = this.items[this.selectIndex]
        this.$emit('search', { q: this.searchString })
      }
    },
    chooseSuggest (i) {
      if (this.selectIndex <= 0 && i === -1) {
        this.selectIndex = this.items.length - 1
      } else if (this.selectIndex >= this.items.length - 1 && i === 1) {
        this.selectIndex = 0
      } else {
        this.selectIndex += i
      }

      this.searchString = this.items[this.selectIndex]
    },
    handleInput (e) {
      this.searchString = e.target.value
      if (e && e.inputType === 'insertCompositionText') {
        this.searchString = e.target.value
      }
      if (this.isCustom) {
        this.custom.func(this.searchString)
        return
      }
      this.items = []
      if (this.loadHelpId) clearTimeout(this.loadHelpId)
      if (this.searchString.length >= MIN_SEARCH_LENGTH) {
        const str = String(this.searchString)
        this.loadHelpId = setTimeout(() => {
          this.loadHelp(str)
        }, 500)
      }
    },
    async loadHelp (str) {
      const query = {
        limit: this.limit,
        offset: 0,
        q: str
      }

      if (this.variation === BASE_TYPE) {
        this.selectIndex = -1
        this.items = await this.$api.search.suggest(query)
      } else {
        const data = await this.$api.legalUnites.search(query)
        this.items = data.items
      }
    },
    focusIn (e) {
      this.$emit('scroll-me', e)
      clearTimeout(this.hideTimer)
      this.isActive = true
      this.focused = true
    },
    focusOut (e) {
      this.focused = false
      this.$refs.input.blur()
      return this.delay(250).then(() => {
        this.isActive = false
      })
    },
    mouseEnter (e) {
      this.mouseIn = true
      clearTimeout(this.hideTimer)
    },
    instantNavigation () {
      this.$api.search.combinedSearch({
        limit: 1,
        offset: 0,
        q: this.items[0]
      })
        .then(res => {
          this.focusOut()
          this.$router.push(`/entreprise/${this.items[0]}-${res.items[0].siren}`)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  padding: 14px 33px;
}
.button-text {
  font-weight: 600;
}
.input-wrapper {
  position: relative;
}
.search-input-wrapper {
  position: relative;
  width: 100%;

  .search-input {
    display: flex;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: var(--title);

    background: var(--white);
    width: 100%;
    padding: 20px 200px 20px 60px;
    border-radius: 50px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }
  }

  .search-icon {
    @include centrifyY;
    width: 24px;
    height: 24px;
    left: 24px;
    z-index: 3;
    --svg-color: var(--brand);
    transform-origin: center;
  }

  .button {
    @include centrifyY;
    right: 6px;
    z-index: 4;
    --svg-color: var(--white);

    &:hover svg,
    &:focus svg {
      animation: little-jump 2s ease 0s infinite normal forwards;
      --base-offset-x: 0px;
      --max-offset-x: 5px;
    }
  }

  .input-label {
    z-index: 4;
    position: relative;
    display: block;
  }

  .next-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
  }

  @include tablet-landscape {
    & {
      .button {
        .next-icon {
          animation: little-jump 5s ease 0s infinite normal forwards;
          --base-offset-x: 0px;
          --max-offset-x: 5px;
        }
      }
    }
  }

  &.main {
    .search-input {
      padding-right: var(--input-padding-right, 190px);
    }
    @include phone {
      .search-input {
        padding-block: 23px;
        padding-right: 70px;
        padding-left: 48px;
        font-size: 16px;
        line-height: 22px;
      }
      .search-icon {
        left: 16px;
      }
      .button-text {
        display: none;
      }
      .button {
        @include centrifyY;
        right: 10px;
        width: 48px;
        height: 48px;
        padding: 0;
        .next-icon {
          width: 30px;
          height: 30px;
          margin-left: 0;
        }
      }
    }
  }

  &.thank-you-page-variation {
    .input-label {
      width: 100%;
    }
    .search-input {
      padding-top: 18px;
      padding-bottom: 18px;
      padding-right: 180px;
      background-color: #f4f5f6;
      border: 2px solid #f4f5f6;
      transition: all 0.3s;
      &:focus {
        border: 2px solid var(--brand);
        transition: all 0.3s;
      }
    }

    .button {
      padding: 20px 30px;
      right: 0;
      font-size: 14px;
    }
    @include phone {
      .search-input {
        padding-right: 70px;
        padding-left: 48px;
        padding-block: 17px;
        font-size: 16px;
        line-height: 22px;
      }
      .button {
        width: 48px;
        height: 48px;
        display: flex;
        padding: 0;
        right: 6px;
      }
      .button-text {
        display: none;
      }
      .next-icon {
        margin: 0;
        width: 30px;
        height: 30px;
      }
      .search-icon {
        left: 16px;
      }
    }
  }

  &.error-page-variation {
    color: var(--white);

    .search-input {
      background: rgba(255, 255, 255, 0.1);
      color: inherit;
      padding-block: 22px;
      padding-right: 180px;
      &::placeholder {
        color: inherit;
        font-weight: 500;
      }
    }

    .search-icon {
      --svg-color: currentColor;
      left: 20px;
    }
    .button {
      padding: 20px 30px;
      right: 0;
      font-size: 14px;
      background: rgba(255, 255, 255, 0.1);
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      .next-icon {
        width: 6px;
        margin-block: 2px;
        margin-right: 9px;
        margin-left: 17px;
      }
    }
    @include tablet-landscape {
      .input-label {
        text-align: left;
      }
      .search-icon {
        display: none;
      }
      .search-input {
        padding-left: 20px;
      }
    }
    @include phone {
      .button {
        position: relative;
        margin: 8px auto 0;
        width: auto;
        padding: 13px 26px;
        top: 0;
        transform: none;
        right: auto;
        .next-icon {
          margin-left: 11px;
        }
        .button-text {
          font-size: 12px;
          line-height: 1.5;
        }
      }
      .search-input {
        line-height: 22px;
        font-weight: 600;
        font-family: Poppins, sans-serif;
        padding: 13px 48px 13px 12px;
        font-size: 16px;
      }

      .search-icon {
        left: auto;
        right: 12px;
        display: block;
      }
    }
    @media (max-width: 320px) {
      .search-input {
        padding-block: 16px;
      }
      .button {
        padding: 10px 20px;
      }
      .button .button-text {
        font-size: 10px;
        line-height: 1.5;
      }
    }
  }

  &.beta {
    color: var(--white);

    .search-input {
      background: #3769ee;
      color: inherit;

      &::placeholder {
        color: inherit;
      }
    }

    .button {
      color: inherit;
      letter-spacing: 0.01em;
      font-weight: 600;
      font-size: var(--font-size-small);
      line-height: var(--line-height-small-l1);
      top: 0;
      bottom: 0;
      transform: none;
      right: 0;

      .next-icon {
        height: 10px;
        width: 6px;
      }
    }

    .search-icon {
      --svg-color: currentColor;
    }
    @include tablet-landscape {
      z-index: 2;

      .button {
        position: relative;
        min-width: 228px;
        padding: 17px 22px;
        height: 52px;
        margin: 8px auto 0;
        z-index: 1;

        .next-icon {
          margin-left: 16px;
        }
      }

      .search-input {
        padding-right: 0.25em;
      }
    }
  }
}
</style>
