export default {
  computed: {
    user () {
      return this.$store.getters['user/index']
    },
    hasSubscription () {
      return this.$store.getters['user/hasSubscription']
    }
  },
  methods: {
    getUser () {
      return this.$store.getters['user/index']
    },
    getSubscription () {
      return this.$store.getters['user/hasSubscription']
    }
  },
  watch: {
    user (v, o) {
      if (v === null || v === undefined) {
        if (this.onLogout) this.onLogout(o)
      } else if (o === null || o === undefined) {
        if (this.onLogin) this.onLogin(v)
      } else if (this.userUpdate) this.userUpdate(v, o)
    }
  }
}
