import Auth from '../api/Auth'
import Meta from '../api/Meta'
import LegalUnites from '../api/LegalUnites'
import Requests from '~/api/Requests'
import Inpi from '~/api/Inpi'
import Services from '~/api/Services'
import Geo from '~/api/Geo'
import Beneficiaires from '~/api/Beneficiaires'
import Representants from '~/api/Representants'
import Search from '~/api/Search'
import Users from '~/api/Users'
import Courts from '~/api/Courts'
import SiteClones from '~/api/SiteClones'

export default (context, inject) => {
  const { $axios } = context
  const functional = {
    auth: new Auth($axios),
    meta: new Meta($axios),
    legalUnites: new LegalUnites($axios),
    requests: new Requests($axios),
    inpi: new Inpi($axios),
    services: new Services($axios),
    geo: new Geo($axios),
    beneficiaires: new Beneficiaires($axios),
    representants: new Representants($axios),
    search: new Search($axios),
    users: new Users($axios),
    courts: new Courts($axios),
    siteClones: new SiteClones($axios)
  }

  inject('api', functional)
  // eslint-disable-next-line no-param-reassign
  context.$api = functional
}
