import * as defaultFilter from '@/services/defaultFilter.js'

export const state = () => ({
  ...defaultFilter.state(),
  data: [
    {
      title: 'Participant à une groupement d’intérêt économique',
      id: 'BE_GIE-GOA_DBE'
    }, {
      title: 'Participant à des organismes de placement collectif',
      id: 'BE_PLA-OPC_DBE'
    }, {
      title: 'Bénéficiaire effectif',
      id: 'BE_SOC-SOC_DBE'
    }, {
      title: 'Autre',
      id: 'autre'
    }
  ]
})
export const getters = {
  ...defaultFilter.getters,
  mapper: state => value => {
    if (value === null) return null
    const res = []
    for (const i of value) {
      res.push(...i.toUpperCase().split('-'))
    }
    return res
  }

}
export const mutations = {
  ...defaultFilter.mutations

}
