import Api from './Api'

class Auth extends Api {
  constructor (_axios) {
    super(_axios, '/auth')
  }

  login (params) {
    return this.post('/login-by-email', params)
  }

  logout () {
    return this.post('/logout')
  }

  registration (params) {
    return this.post('/registration', params)
  }

  me () {
    return this.get('/me')
  }

  getCodeRecovery (params) {
    return this.post('/password-recovery', params)
  }

  passwordRecovery (params) {
    return this.put('/password-recovery', params)
  }

  checkCode (params) {
    return this.get('/password-recovery', params)
  }
}

export default Auth
