export const state = () => ({
  allRegions: null,
  allDepartements: null
})

export const getters = {
  allRegions (state) {
    return state.allRegions
  },
  allDepartements (state) {
    return state.allDepartements
  }
}

export const actions = {}

export const mutations = {
  setRegions (state, allRegions) {
    state.allRegions = allRegions
  },
  setDepartements (state, allDepartements) {
    state.allDepartements = allDepartements
  }
}
