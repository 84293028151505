import Vue from 'vue'

export default ({
  store, $axios, $config, error
}) => {
  const defaultErrorHandler = Vue.config.errorHandler
  Vue.config.errorHandler = function (err, vm, info) {
    console.error(err, info)
    if (process.client) {
      if ($config.debug && vm.$vnode.tag) {
        const data = {
          error: err,
          info,
          component_data: JSON.stringify(vm.$data),
          component_name: vm.$vnode.tag,
          tag_name: vm.$el.tagName,
          url: vm.$el.baseURI,
          user_id: store.getters['user/index']?.id
        }
        try {
          $axios.$post('/errors', data)
        } catch (e) {
          console.error(e)
        }
      }
      defaultErrorHandler(err, vm, info)
    } else {
      error({ statusCode: 500 })
    }
  }
}
