import * as defaultFilter from '@/services/defaultFilter.js'
import Service from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state()
})
export const getters = {
  ...defaultFilter.getters,
  encoder: state => value => {
    const res = {}
    if (value) {
      if (value.ids && value.ids.length) res.ids = Service.encoders.ArrayEncode(value.ids)
    }
    return res
  },
  decoder: state => value => {
    const res = {}
    if (value) {
      if (value.ids && value.ids.length) res.ids = value.ids
    }
    return res
  },
  mapper: state => value => value.ids

}
export const mutations = {
  ...defaultFilter.mutations

}
