// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search-input-suggest-wrapper{position:absolute;top:0;left:0;right:0;z-index:3}.search-input-suggest-wrapper .main{margin-top:72px}.search-input-suggest-wrapper.hidden{display:none}.search-input-suggest-wrapper.active,.search-input-suggest-wrapper.hidden.active{display:block}@media(max-width:1160px){.search-input-suggest-wrapper.error-page{z-index:4}.search-input-suggest-wrapper.error-page .main{margin-top:56px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet-landscape": "1160px",
	"tablet-portrait": "900px",
	"phone": "640px"
};
module.exports = ___CSS_LOADER_EXPORT___;
