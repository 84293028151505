<template>
  <div class="__spinner-wrapper">
    <div class="__spinner" :style="variables" />
  </div>
</template>

<script>
export default {
  name: 'SimpleSpinner',
  props: {
    color: {
      default: 'var(--brand)'
    },
    thickness: {
      default: '7px',
      validator (value) {
        if (value.toLowerCase() === 'auto') return true
        const val = `${value}`.trim().toLowerCase()
        if (val.includes(' ')) return false
        if (+val || Number.parseFloat(val)) return true
        return /^\d+(%|px|em|rem|vw|vh)$/.test(val)
      }
    },
    size: {
      default: '30px',
      validator (value) {
        if (value.toLowerCase() === 'auto') return true
        const val = `${value}`.trim().toLowerCase()
        if (val.includes(' ')) return false
        if (+val || Number.parseFloat(val)) return true
        return /^\d+(%|px|em|rem|vw|vh)$/.test(val)
      }
    }
  },
  computed: {

    variables (props) {
      const sval = `${props.size}`.trim().toLowerCase()
      let size = sval
      if (+sval) size = `${+sval}px`
      const tval = `${props.thickness}`.trim().toLowerCase()
      let thickness = tval
      if (thickness === 'auto') {
        const sfl = Number.parseFloat(size)
        if (sfl) {
          thickness = `${Math.floor(sfl / 5)}${size.match(/[a-zA-Z]+/)[0]}`
        } else thickness = '7px'
      } else if (+tval) thickness = `${+tval}px`

      return {
        '--color': props.color,
        '--thickness': thickness,
        '--size': size
      }
    }
  }

}
</script>

<style lang="scss">
.__spinner {
  height: var(--size);
  width: var(--size);
  max-height: var(--size);
  max-width: var(--size);
  overflow: visible;
  border-left: var(--thickness) solid var(--color);
  border-bottom: var(--thickness) solid var(--color);
  border-right: var(--thickness) solid var(--color);
  border-top: var(--thickness) solid transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
