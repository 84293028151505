import * as defaultFilter from '@/services/defaultFilter.js'
import service from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters,
  mapper: state => value => service.mappers.boolean(value, 'approximative'),
  encoder: state => value => service.encoders.boolean(value, 'approximative'),
  decoder: state => value => service.decoders.boolean(value, 'approximative')

}
export const mutations = {
  ...defaultFilter.mutations

}
