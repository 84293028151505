import * as defaultFilter from '@/services/defaultFilter.js'
import Service from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state()

})
export const getters = {
  ...defaultFilter.getters,
  validator: state => value => Service.validators.numberInterval(value),
  mapper: state => value => Service.mappers.numberInterval(value, 'profitability_on_equity', { from: 'from', to: 'to' })

}
export const mutations = {
  ...defaultFilter.mutations

}
