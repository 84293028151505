import Router from 'vue-router'

const cacheUrlMatchers = [
  {
    hostname: 'webcache.googleusercontent.com',
    pathname: '/search',
    getRealUrl: url => {
      const parsed = new URL(url)
      const q = parsed.searchParams.get('q')

      const matched = q.match(/^cache:[^:]+:(.+)/)

      if (!matched) throw new Error(`Can't extract realUrl from "${url}"`)

      const extracted = matched[1].trim()

      if (!extracted.startsWith('https://')) return `http://${extracted}`

      return extracted
    }
  }
]
const getCache = fullUrl => {
  const parsedUrl = new URL(fullUrl)

  const found = cacheUrlMatchers
    .find(({ hostname, pathname }) => parsedUrl.hostname === hostname && parsedUrl.pathname === pathname)

  return found || null
}

const getFullPath = (fullUrl, base) => {
  const parsed = new URL(fullUrl)

  let fullPath = parsed.pathname + parsed.search + parsed.hash

  if (fullPath.startsWith(base)) fullPath = fullPath.replace(base, '')
  if (!fullPath.startsWith('/')) fullPath = `/${fullPath}`

  return fullPath
}
export const patchNuxtRouter = (router, href) => {
  const origRouterResolve = router.resolve.bind(router)

  router.resolve = () => {
    router.resolve = origRouterResolve
    return origRouterResolve(href)
  }
}

export function createRouter (ssrContext, createDefaultRouter, routerOptions, config) {
  const defaultRouter = createDefaultRouter(ssrContext, config)
  const options = routerOptions || defaultRouter.options
  if (process.server) return defaultRouter
  // Check for current location is cache
  const cache = getCache(window.location.href) // 2

  if (!cache) return defaultRouter
  // Change mode if we in cache, to prevent "Uncaught DOMException"
  const router = new Router({
    ...options,
    mode: 'abstract'
  })
  const realUrl = cache.getRealUrl(window.location.href)
  const realFullPath = realUrl
    ? getFullPath(realUrl, options.base)
    : '/'
  router.push = defaultRouter.push

  patchNuxtRouter(router, realFullPath)

  router.cache = cache
  router.isCache = true

  return router
}
