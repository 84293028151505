/* eslint-disable no-useless-constructor */
/* eslint-disable no-multi-assign */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { Utils } from '~/services/Math/Geometric.js'

export class AnimationBase {
  constructor (fullTime, whatToDo, inTheEnd, onStop, onStart, onRestart, onPause, onResume) {
    this.innerState = {
      request: null,
      elapsed: 0,
      lastTime: 0,
      stop: false,
      pause: false,
      ended: false
    }
    this.state = {
      fullTime: +fullTime,
      onRestart,
      onResume,
      onFrame: whatToDo,
      onStart,
      onPause,
      onStop,
      onEnd: inTheEnd,
      progress: 0, // computable => readonly
      remainsTime: +fullTime,
      autoRestart: false,
      ended: false, // readonly
      lastElapsedMS: 0,
      stopped: false, // readonly
      paused: false// readonly
    }
    this.innerState.requestAnimationFrameFunction = this.___frame
  }

  restartCallback () {}

  resumeCallback () {}

  frameCallback () {}

  startCallback () {}

  resetCallback () {}

  pauseCallback () {}

  stopCallback () {}

  endCallback () {}

  start () {
    this.state.stopped = this.innerState.stop = false
    this.state.paused = this.innerState.pause = false
    this.state.ended = this.innerState.ended = false
    this.innerState.lastTime = performance.now()
    this.startCallback()
    if (this.state.onStart) this.state.onStart(this.state)
    this.innerState.request = requestAnimationFrame(this.innerState.requestAnimationFrameFunction.bind(this))
  }

  restart () {
    this.reset()
    this.restartCallback()
    if (this.state.onRestart) this.state.onRestart(this.state)
    this.start()
  }

  reset () {
    this.state.remainsTime = this.state.fullTime
    this.state.progress = 0
    this.resetCallback()
  }

  stop () {
    this.state.stopped = this.innerState.stop = true
    this.stopCallback()
  }

  pause () {
    this.state.paused = this.innerState.pause = true
    this.pauseCallback()
  }

  resume () {
    this.state.paused = this.innerState.pause = false
    this.state.stopped = this.innerState.stop = false
    if (this.innerState.ended) return
    this.resumeCallback()
    if (this.state.onResume) this.state.onResume(this.state)
    this.innerState.lastTime = performance.now()
    this.innerState.request = requestAnimationFrame(this.innerState.requestAnimationFrameFunction.bind(this))
  }

  ___frame (timeFromOrigin) {
    if (this.innerState.stop) {
      if (this.state.onStop) this.state.onStop(this.state)
      cancelAnimationFrame(this.innerState.request)
      return
    }
    if (this.innerState.pause) {
      if (this.state.onPause) this.state.onPause(this.state)
      cancelAnimationFrame(this.innerState.request)
      return
    }

    this.state.lastElapsedMS = this.innerState.elapsed = timeFromOrigin - this.innerState.lastTime
    if (this.state.lastElapsedMS < 0) this.state.lastElapsedMS = 0
    this.state.remainsTime -= this.innerState.elapsed
    if (this.state.remainsTime < 0) this.state.remainsTime = 0
    this.state.progress = (this.state.fullTime - this.state.remainsTime) / this.state.fullTime * 100
    this.frameCallback()
    if (this.state.onFrame) this.state.onFrame(this.state)

    if (this.state.remainsTime <= 0) {
      if (this.state.autoRestart) { this.restart() } else {
        this.innerState.ended = this.state.ended = true
        cancelAnimationFrame(this.innerState.request)
      }
      this.endCallback()
      if (this.state.onEnd) this.state.onEnd(this.state)
      return
    }
    this.innerState.request = requestAnimationFrame(this.innerState.requestAnimationFrameFunction.bind(this))

    this.innerState.lastTime = timeFromOrigin
  }
}
export class AnimationTimer extends AnimationBase {
  /*
  API:
  object state,
  method start,
  method restart,
  method resume,
  method stop,
  method pause,
  method reset
  */
  constructor (fullTime, whatToDo, inTheEnd, onStop, onStart, onRestart, onPause, onResume) {
    super(fullTime, whatToDo, inTheEnd, onStop, onStart, onRestart, onPause, onResume)
  }
}

export class NumericAnimation extends AnimationBase {
  /**
   * Анимирует изменение числа по времени
   * @param {Number} startValue Стартовое число
   * @param {Number} endValue Конечное число
   * @param {Number} fullTime Время анимации в миллисекундах
   * @param {Function} whatToDo Callback в каждый кадр
   * @param {Function} inTheEnd Callback в момент окончания анимации
   * @param {Function} onStop Callback при остановке анимации
   * @param {Function} onStart Callback при старте анимации
   * @param {Function} onRestart Callback при рестарте анимации
   * @param {Function} onPause Callback при паузе анимации
   * @param {Function} onResume Callback при снятии с паузы анимации
   * @param {Array} bezierPoints Значения точек кривой Безье для сглаживания
   */
  constructor (startValue, endValue, fullTime, whatToDo, inTheEnd, onStop, onStart, onRestart, onPause, onResume, bezierPoints) {
    super(fullTime, whatToDo, inTheEnd, onStop, onStart, onRestart, onPause, onResume)
    let beziers
    if (bezierPoints instanceof Array) {
      if (bezierPoints.length === 2) {
        beziers = bezierPoints
      } else
      if (bezierPoints.length === 4) {
        beziers = [{ x: bezierPoints[0], y: bezierPoints[1] }, { x: bezierPoints[2], y: bezierPoints[3] }]
      }
    }

    Object.assign(this.innerState, {
      startValue: +startValue,
      endValue: +endValue,
      bezierPoints: beziers,
      step: 0
    })
    Object.assign(this.state, {
      value: this.innerState.startValue
    })
    this.innerState.step = (this.innerState.endValue - this.innerState.startValue) / this.state.fullTime
    if (this.innerState.bezierPoints) {
      this.innerState.valueFunctor = this.___bezier
    } else {
      this.innerState.valueFunctor = this.___linear
    }
  }

  frameCallback () {
    this.innerState.valueFunctor.bind(this)()
  }

  ___linear () {
    this.state.value = this.innerState.startValue + this.innerState.step * (this.state.fullTime - this.state.remainsTime)
  }

  ___bezier () {
    this.state.value = this.innerState.startValue
    + (this.innerState.endValue - this.innerState.startValue)
    * this.___cubicBezier((this.state.fullTime - this.state.remainsTime) / this.state.fullTime)
  }

  ___cubicBezier (t) {
    const comp1 = Utils.multiplyPoint((1 - t) ** 3, { x: 0, y: 0 })
    const comp2 = Utils.multiplyPoint(3 * t * (1 - t) ** 2, this.innerState.bezierPoints[0])
    const comp3 = Utils.multiplyPoint(3 * t ** 2 * (1 - t), this.innerState.bezierPoints[1])
    const comp4 = Utils.multiplyPoint(t ** 3, { x: 1, y: 1 })
    return comp1.y + comp2.y + comp3.y + comp4.y
  }
}
