// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".__spinner{height:var(--size);width:var(--size);max-height:var(--size);max-width:var(--size);overflow:visible;border:var(--thickness) solid var(--color);border-top:var(--thickness) solid transparent;border-radius:50%;-webkit-animation:spinner 1s linear infinite;animation:spinner 1s linear infinite}@-webkit-keyframes spinner{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes spinner{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet-landscape": "1160px",
	"tablet-portrait": "900px",
	"phone": "640px"
};
module.exports = ___CSS_LOADER_EXPORT___;
