import * as defaultFilter from '@/services/defaultFilter.js'
import Service from '~/services/searchFiltersService'

export const state = () => ({
  ...defaultFilter.state(),
  data: {
    items: [
      {
        title: 'Le droit de contrôle du conseil d\'administration',
        id: '0'
      },
      {
        title: 'Le droit de changer le conseil d\'administration',
        id: '1'
      },
      {
        title: 'Bénéficiaire indirect par l\'intermédiaire d\'un groupe de personnes',
        id: '2'
      },
      {
        title: 'Est le représentant légal',
        id: '3'
      },
      {
        title: 'Le ou les représentants légaux du placement collectif',
        id: '4'
      }
    ],
    radioItems: [
      'Ou', 'Et'
    ]
  }
})
export const getters = {
  ...defaultFilter.getters,
  encoder: state => value => {
    const res = {}
    if (value) {
      if (value.ids && value.ids.length) res.ids = Service.encoders.ArrayEncode(value.ids)
      if (res.ids && res.ids.length && value.toggle) res.toggle = value.toggle
    }
    return res
  },
  decoder: state => value => {
    const res = {}
    if (value) {
      if (value.ids && value.ids.length) res.ids = value.ids
      if (value.ids && value.ids.length && value.toggle) res.toggle = value.toggle
    }
    return res
  },
  mapper: state => value => {
    if (value === null) return null
    const res = {
      authority_beneficiaires: value.ids,
      authority_beneficiaires_et: value.toggle === 'Et' ? 1 : 0
    }
    Object.defineProperty(res, 'expand', {
      value: true,
      enumerable: false,
      writable: false
    })
    return res
  }

}
export const mutations = {
  ...defaultFilter.mutations

}
